import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Search', function (exports) {
  exports.MapMarker = Backbone.View.extend({
    initialize: function () {
      var icon = {
        path: 'M15,0 C6.715,0 0,6.715 0,15 C0,23.284 15,37 15,37 C15,37 30,23.284 30,15 C30,6.715 23.285,0 15,0 L15,0 Z M14.999,21.537 C11.134,21.537 7.999,18.403 7.999,14.537 C7.999,10.671 11.134,7.537 14.999,7.537 C18.866,7.537 22,10.671 22,14.537 C22,18.403 18.866,21.537 14.999,21.537 L14.999,21.537 Z',
        fillColor: '#747474',
        fillOpacity: 1,
        strokeWeight: 0,
        anchor: new google.maps.Point(15, 37)
      }
      this.options = {
        position: new google.maps.LatLng(this.lat(), this.lng()),
        icon: icon,
        animation: google.maps.Animation.DROP,
        title: this.model.get('provider_name')
      };
      this._setInfoWindow();
    },

    placeOn: function (map) {
      this.marker = new google.maps.Marker(_.extend(this.options, { map: map }));
      this.listenTo(this.model, 'mouseenter', _.bind(this.highlight, this, map));
      this.listenTo(this.model, 'mouseleave', _.bind(this.unhighlight, this));
    },

    showInfoWindow: function (map) {
      this.infoWindow.open(map, this.marker);
      this.highlight();
      unhighlight = _.bind(this.unhighlight, this);
      google.maps.event.addListener(this.infoWindow, 'closeclick', unhighlight);
    },

    closeInfoWindow: function () {
      if (this.infoWindow) {
        this.infoWindow.close();
        this.unhighlight();
      }
    },

    lat: function () { return this.model.get('lat'); },
    lng: function () { return this.model.get('lng'); },

    highlight: function () {
      var hoverIcon = _.defaults({fillColor: "#EF401C"}, this.options.icon);
      this.marker.setIcon(hoverIcon);
      this.marker.setZIndex(1000);
    },

    unhighlight: function () {
      this.marker.setZIndex(0);
      this.marker.setIcon(this.options.icon);
    },

    _setInfoWindow: function () {
      var ratings = $('<div>', {'class': 'ratings'});
      var rating = parseFloat(this.model.get('average_rating'));
      if (rating > 0){
        ratings.raty({
          starType: 'i',
          readOnly: true,
          halfShow: true,
          score: rating,
          hints: [ '', '', '', '', '' ]
        });
      }

      var name = this.model.get('provider_name');
      var providerNameEl = $('<h3>', {text: name, 'class': 'provider_name'})
      providerNameEl.click(_.bind(function () {
        location.href = this.model.profileUrl();
      }, this));
      var contentString = $('<div>', {'class': 'info-window'}).append(ratings).append(
        providerNameEl
      ).append(
        $('<div>', {text: this.model.get('practice_name'), 'class': 'practice_name'})
      ).append(
        $('<div>', {text: this.model.get('street_address'), 'class': 'address'})
      ).append(
        $('<div>', {text: this.model.get('city_state_zip'), 'class': 'city-state'})
      );

      this.infoWindow = new google.maps.InfoWindow({ content: contentString[0] });
    }
  });
});
