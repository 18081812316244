import { namespace } from 'src/namespace';
import '../../models';

export default function() {};
namespace('Doctible.Collections.Providers', function(exports) {
  exports.ProviderLocationHours = Backbone.Collection.extend({
    model: Doctible.Models.Providers.ProviderLocationOfficeHour,
    url: '/api/providers/provider_location_hours'
  });
});
