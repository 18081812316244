import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Support', function (exports) {
  exports.StreetView = Backbone.View.extend({
    el: '.street-review',

    initialize: function () {
      this.render();
    },

    render: function () {
      var locationLatLng = new google.maps.LatLng(this.model.get('lat'), this.model.get('lng'));
      var headingAdjust = this.model.get('heading');

      var mapOptions = {
        center: locationLatLng,
        zoom: 13
      };

      var map = new google.maps.Map(this.$el[0], mapOptions);
      var service = new google.maps.StreetViewService();
      service.getPanoramaByLocation(map.getCenter(), 100, function(dta, status) {
        if(status == google.maps.StreetViewStatus.OK) {
          var streetViewLocation = dta.location.latLng;
          var heading = google.maps.geometry.spherical.computeHeading(streetViewLocation, map.getCenter());
          var streetMap = map.getStreetView()
          streetMap.setOptions({ panControl: false, scrollwheel: false, addressControl: false, linksControl: false, zoomControl: false, motionTrackingControl: false});
          streetMap.setPosition(streetViewLocation);
          streetMap.setPov({ heading: heading + headingAdjust, pitch: 0 });
          streetMap.setVisible(true);
        }
      });
    }
  });
});
