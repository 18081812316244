import 'jquery-ui';
import _ from 'underscore';
import 'underscore/modules/templateSettings.js';
import popover from 'bootstrap';
import { namespace } from 'src/namespace';
import './support/smooth_scroll_link.js';

export default function() {};
namespace('Doctible.Views', function (exports) {
  exports.Body = Backbone.View.extend({
    el: 'body',

    initialize: function () {
      // Use Mustache.js style templating
      _.templateSettings.interpolate = /\{\{(.+?)\}\}/g;
      new Doctible.Views.Support.SmoothScrollLink({el: this.$('[href^=\\#]')});
      new Doctible.Support.readMoreString({});
      new Doctible.Support.readMoreList({});
      // $('[data-toggle="tooltip"]').tooltip();
      var viewsToInitialize = [
        exports.ProviderProfile,
        exports.LocationProfile,
        exports.CheckoutPage,
        exports.ConfirmationPage,
        exports.FaqPage
      ];
      var instantiateIfExists = function (view) {
        if ($(view.prototype.el).length > 0) {
          new view;
        }
      };
      _.each(viewsToInitialize, instantiateIfExists)
    },
  });
});
