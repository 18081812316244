import { namespace } from 'src/namespace';
import './booked_appointment_row.js'

export default function() {};
namespace('Doctible.Views.ProviderPortal.RequestedAppointments', function (exports) {
  exports.BookedAppointments = Backbone.View.extend({
    el: '.booked_appointments',

    initialize: function () {
      this.collection.bind('remove', this.render, this)
      this.appointments = this.collection.map(this._appendModel, this);
    },

    render: function(){
      _.each(this.appointments, function (view) {
        view.remove();
      });
      this.appointments = this.collection.map(this._appendModel, this);
    },

    _appendModel: function (model) {
      var appointment = new Doctible.Views.ProviderPortal.RequestedAppointments.BookedAppointmentRow({ model: model });
      this.$el.append(appointment.$el);
      return appointment;
    }
  });
});
