import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views', function (exports) {
  exports.DefaultSearch = Backbone.View.extend({
    el: '#default-search',

    events: {
      'change select': 'checkValues'
    },

    initialize: function() {
      this.render();
    },

    render: function() {
      this.$('select').select2({ minimumResultsForSearch: Infinity });
    },

    checkValues: function(event) {
      this.$('button').prop('disabled', !$(event.currentTarget).val())
    }
  });
});
