import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Search', function (exports) {
  exports.SearchForm = Doctible.Views.Support.Form.extend({
    className: "search-form form-inline",
    id: "mobile-specialty-search",
    action: "/search_results",
    attributes: {
      method: 'get'
    },

    initialize: function() {
      this.render();
    },

    render: function() {
      this.$el.html(
        '<p class="modal-label">Choose a Specialty</p>' +
        '<select class="form-control specialty" name="si" data-placeholder="Choose a Specialty"></select>' +
        '<p class="modal-label">How will you be paying?</p>' +
        '<select class="form-control" data-placeholder="How will you be paying?" name="insurance">' +
          '<option value="cash">I\'m paying for myself</option>' +
          '<optgroup class="insurances" label="Popular Insurances"></optgroup>' +
        '</select>' +
        '<p class="modal-label">Enter your location</p>' +
        '<input class="form-control area-select" name="area" placeholder="Enter City or Zip">' +
        '<input type="hidden" name="type" value="treatment">' +
        '<input type="hidden" name="sei">' +
        '<button class="btn btn-default" type="submit">Find a Doctor</button>'
      );
    },
  });
});
