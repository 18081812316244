import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.Services', function(exports) {
  exports.EditPage = Backbone.View.extend({
    el: '.services.edit_all',

    events: {
      'click button.save': 'saveCollection',
      'click button.add-services': 'showAddServicesModal',
      'keyup .price input': 'checkInputs'
    },

    initialize: function() {
      this.collection = new Doctible.Collections.ProviderMedicalServices
      var editTable = new exports.EditTable({ collection: this.collection });
      this.listenTo(this.collection, 'sync', this.checkInputs);
    },

    saveCollection: function() {
      this.collection.save({ success: function() {
        Doctible.Support.navigate('/providers/services');
      }});
    },

    showAddServicesModal: function() {
      var modal = new exports.AddServicesModal({ collection: this.collection });
      modal.show();
    },

    checkInputs: function() {
      var pricesExist = _.map(this.$('.price input'), function(input) {
        return $(input).val().length !== 0;
      });
      this.$('button.save').prop('disabled', _.contains(pricesExist, false));
    },
  })
});
