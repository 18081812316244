import { namespace } from 'src/namespace';

export default function () {}
namespace('Doctible.Views.Provider', (exports) => {
  exports.PhotoGallery = Backbone.View.extend({
    className: 'flexslider',

    initialize() {
      this.render();
    },

    render() {
      this.$el.append($('<ul>', { class: 'slides' }));
      this.appendPhotos();
    },

    initiateSlider(start_img_id) {
      // this.$el.flexslider({
      //   animation: 'slide',
      //   startAt: start_img_id,
      //   controlNav: this._controlNav(),
      //   directionNav: this._directionNav()
      // });
      // Replace previous/next text with font awesome icons
      this.$('.flex-prev').text('').append($('<i>', { class: 'fa fa-angle-left' }));
      this.$('.flex-next').text('').append($('<i>', { class: 'fa fa-angle-right' }));
    },

    appendPhotos() {
      this.collection.each(function (photo) {
        const img = $(`<li data-thumb="${photo.get('thumb')}">`)
          .append($('<img>', { src: photo.get('url') }));
        this.$('ul').append(img);
      }, this);
    },

    _controlNav() {
      return (this.collection.length == 1) ? false : 'thumbnails';
    },

    _directionNav() {
      return this.collection.length != 1;
    },
  });
});
