import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.Services', function(exports) {
  exports.EditServiceModal = Doctible.Views.Support.Modal.extend({
    additionalEvents: {
      'click button.edit': 'editService'
    },

    postInitialize: function() {
      this.$('form').validate();
    },

    modalHeaderContent: function() {
      this.$('.modal-header').remove();
    },

    modalBodyContent: function() {
      return _.template($('#edit-service-modal').text())(this.modelData());
    },

    onHidden: function() {
      this.remove();
    },

    editService: function() {
      if (this.$('form').valid()) {
        var hide = _.bind(this.hide, this);
        this.model.save(this.formData(), { success: hide });
      }
    },

    modelData: function() {
      return _.defaults(this.model.toJSON(), {
        cash_price: null,
        quick_note: null
      })
    },

    formData: function() {
      return {
        cash_price: this.$('[name="cash_price"]').val(),
        quick_note: this.$('[name="quick_note"]').val()
      }
    }
  });
});
