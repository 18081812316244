// import 'messenger/build/js/messenger';
import { namespace } from 'src/namespace';

export default function () {}
namespace('Doctible.Collections.Providers', (exports) => {
  exports.LocationReviewSites = Backbone.Collection.extend({
    model: Doctible.Models.Providers.LocationReviewSite,
    url: '/api/providers/location_review_sites',
  });
});
