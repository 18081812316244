import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.RequestedAppointments', function (exports) {
  exports.PastAppointmentRow = Doctible.Views.ProviderPortal.RequestedAppointments.AppointmentRow.extend({
    events: {
      'click .view-appointment': 'launchDialog'
    },

    templateID: "#past_appointment_template",
    modalKlass: Doctible.Views.ProviderPortal.RequestedAppointments.AppointmentModal
  });
});
