import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Models.Providers', function(exports) {
  exports.LocationReviewSite = Backbone.Model.extend({
    urlRoot: '/api/providers/location_review_sites',

    asHash: function () {
      return _.extend(this.toJSON(), {
        activeStatusDisplay: this.activeStatusDisplay(),
      });
    },

    activeStatusDisplay: function() {
      if (this.get('active')) {
        return "Deactivate"
      } else {
        return "Activate"
      }
    }
  });
});
