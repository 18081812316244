import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Provider', function (exports) {
  exports.Calendar = Doctible.Views.Calendar.extend({
    initialize: function () {
      this.defaults = _.extend(this.defaults, {
        titleFormat: '[]',
        columnFormat: 'dddd \n MMM D',
        defaultView: 'basicWeek',
        height: 340
      });
    },

    eventClick: function (event) {
      if (!event.closed) {
        this.trigger('datePicked', {
          ei: event.id,
          es: event.start.format("YYYY-MM-DD")
        });
      }
    },

    eventMouseover: function() {},
    eventMouseout: function() {},
  });
});
