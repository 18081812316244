import { namespace } from 'src/namespace';
import moment from 'moment';

export default function() {};
namespace('Doctible.Views.External', function (exports) {
  exports.CalendarDay = Backbone.View.extend({
    className: 'calendar-day',

    events: {
      'click .more': 'showMore'
    },

    initialize: function() {
      this.render();
    },

    render: function() {
      this.$el.empty();
      this.appendPlaceholders();
      var date = this.model.get('date').format('ddd, <b>MMM D</b>');
      this.$el.append($('<div>', { class: 'header', html: date }));
      // If there is a closed event, we shouldn't show any events
      if (this.collection.findWhere({ 'closed': true })) return;
      if (this.collection.length > 5)
        this.$el.append($('<div>', { class: 'more', text: 'more' }));
      this.collection.each(_.bind(function(model) {
        var event = $('<div>', {
          class: 'event',
          text: moment(model.get('start')).format('h:mm A'),
          data: { 'event-id': model.id }
        });
        this.$el.append(event);
      }, this));
      this.delegateEvents();
    },

    appendPlaceholders: function() {
      _.each(_.range(5), _.bind(this.appendPlaceholder,this));
    },

    appendPlaceholder: function() {
      var placeholder = $('<div>', { class: 'placeholder', text: '-' });
      this.$el.append(placeholder);
    },

    showMore: function() {
      this.eventsScrolled = this.eventsScrolled || 0;
      // Scrolling 4 at a time
      this.eventsScrolled += 4;
      // Reset to top event if we've reached the end
      if (this.eventsScrolled >= this.collection.length)
        this.eventsScrolled = 0;
      var scrolled = this.$('.event').first().outerHeight(true);
      this.$('.event').css('top', -scrolled * this.eventsScrolled + 'px' );
    }
  });
});
