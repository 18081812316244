import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.External', function (exports) {
  exports.Modal = Backbone.View.extend({
    el: '.appointment-modal',

    events: {
      'click #close-button, .close-btn': 'closeModal'
    },

    initialize: function () {
      var popupEl = this.$('.availability-popup-modal');
      var providerJSON = popupEl.data('provider-json');
      var model = new Doctible.Models.Provider(providerJSON);
      new exports.Provider({ model: model });
    },

    closeModal: function () {
      parent.postMessage("closeWindow","*");
      this.remove();
    }
  })
})
