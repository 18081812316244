import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Search', function (exports) {
  exports.SearchFormModal = Doctible.Views.Support.Modal.extend({
    id: 'search-form-modal',

    postInitialize: function() {
      this.$el.on('show.bs.modal', function() {
        $('body').addClass('no-modal-backdrop');
      });
    },

    postRender: function() {
      this.searchForm = new Doctible.Views.Search.SearchForm();
    },

    modalHeaderContent: function() {
      return '<h2>Search</h2>';
    },

    modalBodyContent: function() {
      return this.searchForm.$el;
    },

    modalFooterContent: function() {
    },

    onShown: function () {
      this.mobile_search = new Doctible.Views.MobileSpecialtySearch();
      this.mobile_search.render();
    },

    onHidden: function () {
      $('body').removeClass('no-modal-backdrop');
      this.remove();
    }
  })
})
