import { namespace } from 'src/namespace';
import './schedules/calendar.js'

export default function() {};
namespace('Doctible.Views.ProviderPortal', function(exports) {
  exports.SchedulesPage = Backbone.View.extend({
    el: '.schedules.show',

    initialize: function() {
      this.calendar = new Doctible.Views.ProviderPortal.Schedules.Calendar;
    },
  });
});
