import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.External', function (exports) {
  exports.PreferredTimesSelector = Backbone.View.extend({
    el: '#preferred-times-selection',

    events: {
      'click .preferred-time': 'unSelectEvent'
    },

    initialize: function() {
      this.$el.show();
    },

    render: function(preferred_only) {
      var widget = Doctible.Views.AppointmentWidget.Calendar.extend({
        PREFERRED_ONLY: preferred_only
      });
      var calendar = new widget({ el: '#calendar-widget', model: this.model });
      this.listenTo(calendar, 'eventSelected', this.setSelected);
    },

    setSelected: function(eventData) {
      this.selected = { provider_scheduled_event_id: eventData.ei };
      this.trigger('responseReady');
    },

    unSelectEvent: function() {
      this.selected = null;
    },

    response: function() {
      return this.selected || this.selectedTimesResponse();
    },

    selectedTimesResponse: function() {
      return {
        preferred_times: _.map(this.$('.preferred-time.selected'), function(el) {
          return $(el).data('preferred-time');
        }),
        preferred_date: this.$('.day.selected').data('date')
      };
    },

    anySelected: function() {
      return this.$('.preferred-time.selected').length > 0;
    },

    hide: function() { this.$el.hide(); },

    show: function() { this.$el.show(); }
  });
});
