import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal', function (exports) {
  exports.ProviderPhotoUploader = Backbone.View.extend({
    el: 'input.provider-photo',

    events: {
      'change': 'uploadPhoto'
    },

    uploadPhoto: function(event) {
      var currentEl = $(event.currentTarget);
      var file = event.currentTarget.files[0];
      if (!file) { return; };
      var url = '/providers/profile/upload_picture';
      var xhr = new XMLHttpRequest();
      var fd = new FormData();
      xhr.upload.onprogress = _.bind(this.updateProgress, this);
      xhr.open('POST', url, true)
      this.progressBar = new exports.ProgressBar()
      this.progressBar.show();
      var progressBar = this.progressBar;
      xhr.onreadystatechange = function() {
        if (xhr.readyState == 4 && xhr.status == 200) {
          var key = currentEl.data('photo-key');
          var photoUrl = JSON.parse(xhr.responseText)[key];
          currentEl.parent().css('background', 'url(' + photoUrl + ')')
                            .css('background-size', '100px 100px');
        }
        progressBar.hide();
      };
      fd.append(currentEl.attr('name'), file);
      xhr.send(fd);
    },

    updateProgress: function(event) {
      if (event.lengthComputable) {
        var progress = event.loaded / event.total;
        // TODO: get this progress update to fire more than once
        this.progressBar.updateWidth(100);
      } else {
        this.progressBar.updateWidth(100);
      }
    }
  });
});
