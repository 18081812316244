import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.AppointmentWidget', function(exports) {
  exports.ServicesSelect = Backbone.View.extend({
    initialize: function() {
      this.render();
    },

    render: function() {
      var select = this.$el
      this.collection.each(function(model) {
        select.append(
          $('<option>', {
            value: model.id,
            name: 'service',
            text: model.get('description')
          })
        );
      });
    }
  });
});
