import Calendar from './calendar.js';
import PaymentMethodSelect from './payment_method_select.js';
import ServicesSelect from './services_select.js';
import Show from './show.js';

export default {
  Calendar,
  PaymentMethodSelect,
  ServicesSelect,
  Show,
};
