import { namespace } from 'src/namespace';

export default function () {}
namespace('Doctible.Support', (exports) => {
  exports.navigate = function (address) {
    location.href = address;
  };

  exports.destroyProviderScheduledEvents = function (params, view) {
    $.ajax({
      url: `/api/provider_scheduled_events/${params.id}?all_occurrence=${params.all_occurrence}`,
      type: 'DELETE',
      success(resp) {
        location.reload();
      },
      error(xhr, status) {
      },
    });
  };

  exports.startCheckoutSession = function (params) {
    $.ajax({
      url: '/checkout_session',
      type: 'POST',
      data: params,
      success(resp) {
        Doctible.Support.navigate('/checkout');
      },
      error(xhr, status) {
      },
    });
  };

  exports.getPatientContactCount = function (params, view) {
    $.ajax({
      url: '/api/providers/review_requests/patient_contact_count',
      type: 'GET',
      data: params,
      success(resp) {
        view.updateDescription(resp);
      },
      error(xhr, status) {
      },
    });
  },

  exports.recordLocationReviewSiteClick = function (params, view) {
    $.ajax({
      url: '/api/providers/location_review_sites/record_click',
      type: 'POST',
      data: params,
      success: _.bind(view.updateToken, view),
    });
  },

  exports.remindPatientReviewRequest = function (params, view) {
    $.ajax({
      url: '/api/providers/review_requests/remind_patient',
      type: 'POST',
      data: params,
      success(resp) {
        view.trigger('reminder_sent', resp);
      },
      error(xhr, status) {
        // Messenger().post({
        //   message: JSON.parse(xhr.responseText)['error'],
        //   type: 'error'
        // })
      },
    });
  },

  exports.addCustomFormValidators = function () {
    jQuery.validator.addMethod(
      'greaterThan',
      (value, element, params) => {
        if (!/Invalid|NaN/.test(new Date(value))) {
          return new Date(value) > new Date($(params).val());
        }

        return isNaN(value) && isNaN($(params).val())
            || (Number(value) > Number($(params).val()));
      },
      'Must be greater than {0}.',
    );
  };

  exports.readMoreString = function () {
    $('.collapsable-string').each(function (index) {
      const str = $(this).html();
      const limit = $(this).data('limit') || 200;
      if (limit > str.length) return;

      const strIndex = str.indexOf(' ', limit);
      const firstContent = str.substring(0, strIndex);
      const readMoreContent = str.substring(strIndex, str.length + 1);

      $(this).html(`${firstContent
      }<span class='read-more-toggle'>..Read More</span>`
                    + `<span class='read-more-content'>${readMoreContent}</span>`);

      const moreContent = $('.read-more-content', this);
      moreContent.addClass('hidden');

      $('.read-more-toggle', this).click(function () {
        $(this).hide();
        moreContent.removeClass('hidden');
      });
    });
  };

  exports.readMoreList = function () {
    $('.collapsable-list').each(function (index) {
      const limit = $(this).data('limit') || 10;
      const readMoreText = $(this).data('read-more-text') || 'See More';
      const readMoreClass = $(this).data('read-more-class') || 'see-more-list-link';

      const offset = limit - $('.list-item', this).length;
      if (offset >= 0) return;

      $(this).append(`<p class='see-more'><a class='${readMoreClass}'>${readMoreText}</a></p>`);

      const hiddenItems = $(`.list-item:gt(${limit - 1})`, this);
      hiddenItems.addClass('hidden');
      $('.see-more', this).click(function () {
        $(this).hide();
        hiddenItems.removeClass('hidden');
      });
    });
  };
});
