import { namespace } from 'src/namespace';
import './past_appointment_row.js'

export default function() {};
namespace('Doctible.Views.ProviderPortal.RequestedAppointments', function (exports) {
  exports.PastAppointments = Backbone.View.extend({
    el: '.past_appointments',

    initialize: function () {
      this.collection.bind('sync', this.render, this)
      this.appointments = this.collection.map(this._appendModel, this);
    },

    _appendModel: function (model) {
      var appointment = new Doctible.Views.ProviderPortal.RequestedAppointments.PastAppointmentRow({ model: model });
      this.$el.append(appointment.$el);
      return appointment;
    }
  });
});
