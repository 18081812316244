import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Search.Filters', function(exports) {
  exports.Base = Backbone.View.extend({
    events: {
      'click label.filter': 'toggle',
      'click input': 'set',
      'change select': 'set',
      'click .clear': 'reset'
    },

    toggle: function() {
      this.$el.toggleClass('closed');
    },

    set: function() {
      var value = this.value();
      this.$('.clear').toggleClass('hidden', !value);
      this.setValue(value);
    },

    setValue: function(value) {
      // Override this for more specific filter behavior or set paramName
      // property in filter view
      this.collection.updateFilter(this.paramName, value);
    },

    setInitial: function() {
      var currentValue = $('.search-results.index').data(this.paramName)
      if (!currentValue) return this.bindEvents();
      this.setInputValue(currentValue);
      // If no input value was set, we assume it is not a valid option and
      // avoid updating the param in the collection
      if (!this.value()) return;
      this.$('.clear').removeClass('hidden');
      this.collection.updateFilterWithoutReset(this.paramName, currentValue);
    },

    // Select2 event filters need to be set up after setting initial values
    // to avoid firing the event handler when that initial change event is
    // triggered
    bindEvents: function() {},

    // Sets the input without changing the collection or triggering events
    setInputValue: function(value) {
      this.$('input[value=' + value + ']').attr('checked', true)
    },

    reset: function() {
      this.clear();
      this.set();
    },

    clear: function() {
      this.$('input').removeAttr('checked');
    },

    value: function() {
      return this.$('input:checked, select').val();
    }
  })
});
