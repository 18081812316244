import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Support', function(exports) {
  exports.TemplateModal = Backbone.View.extend({
    additionalEvents: {},

    events: function() {
      return _.extend({
        'shown.bs.modal': 'onShown',
        'hidden.bs.modal': 'onHidden',
        'hide.bs.modal': 'onHide'
      }, this.additionalEvents)
    },

    initialize: function() {
      // Use a blank model if one wasn't passed in so we can use ModelTemplate
      this.render();
    },

    render: function() {
      this.setElement(_.template($(this.templateID).text())());
      $('body').append(this.$el);
      this.postRender();
    },

    // Override in inheriting object if anything else needs to be done by render
    postRender: function() {},

    show: function () {
      this.$el.modal('show')
    },

    hide: function () {
      this.$el.modal('hide')
    },

    onShown: function () {},

    onHidden: function () {
      this.remove();
    },

    onHide: function() {}
  });
});
