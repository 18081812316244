import { namespace } from 'src/namespace';
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
// import '@fullcalendar/common/main.css';
// import '@fullcalendar/timegrid/main.css';
import './available_event.js';
import './booked_event.js';
import './checked_in_event.js';
import './confirmed_event.js';
import './new_event_modal.js';
import './unavailable_event.js';

export default function () {}
namespace('Doctible.Views.ProviderPortal.Schedules', (exports) => {
  exports.Calendar = Backbone.View.extend({
    el: '#calendar',

    initialize() {
      this.listenTo(Backbone, 'event-modified', this.refetch);
      this.calendar = new Calendar(this.$el[0], {
        customButtons: {
          addAvailable: {
            text: 'Add Available',
            click: this.newEventModal,
          },
          createAppointment: {
            text: 'Create an Appointment',
            click: this.createAppointment,
          },
        },
        timeZone: this.el.dataset.timeZone,
        headerToolbar: {
          right: 'prev,next today addAvailable createAppointment',
          left: 'title',
        },
        plugins: [interactionPlugin, timeGridPlugin],
        initialView: 'timeGridWeek',
        editable: true,
        firstDay: 1,
        eventContent: _.bind(this.eventContent, this),
        eventDidMount: _.bind(this.eventDidMount, this),
        eventWillUnmount: _.bind(this.eventWillUnmount, this),
        eventSources: [{
          url: '/api/provider_scheduled_events/all',
          cache: true,
        }],
      }),
      this.render();
    },

    render() {
      this.calendar.render();
      this.delegateEvents();
    },

    refetch() {
      const eventSources = this.calendar.getEventSources();
      eventSources[0].refetch();
    },

    createAppointment() {
      const modal = new Doctible.Views.ProviderPortal.AppointmentModal();
      modal.show();
    },

    eventDidMount(info) {
      const v = new Doctible.Views.ProviderPortal.Schedules.Event({ el: info.el, model: info.event });
    },

    eventWillUnmount(info) {
      info.event.remove();
    },

    eventContent(info) {
      const { status } = info.event.extendedProps;
      let content = `${info.timeText} ${info.event.title}`;

      if (status == 'available') {
        content += '<span class="popovericon delete-event" title="Delete"><i class="fa fa-trash-o pop"></i></span>';
      }
      return { html: content };
    },

    viewRender(view) {
      // high enough to avoid scrollbars
      const height = view.name === 'month' ? 500 : 1056;

      this.$el.fullCalendar('option', 'contentHeight', height);
      this.$el.fullCalendar('removeEvents');
    },

    selectEvent(start, end) {
      const modal = new Doctible.Views.ProviderPortal.Schedules.NewEventModal();
      modal.setTimes(start, end);
      modal.show();
    },

    newEventModal() {
      const modal = new exports.NewEventModal();
      modal.show();
    },
  });
});
