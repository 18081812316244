import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal', function(exports) {
  exports.AppointmentReminders = Backbone.View.extend({
    el: '.appointments.reminders',

    initialize: function() {
      this.$('.panel-heading').click(_.bind(this.togglePanel, this))
    },

    togglePanel: function(event) {
      $(event.currentTarget).parent('.panel').toggleClass('open')
    }
  })
})
