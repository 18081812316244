import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Provider', function (exports) {
  exports.PhotoGalleryModal = Doctible.Views.Support.Modal.extend({
    addModalContent: function () {
      this.photoGallery = new exports.PhotoGallery({
        collection: this.setPhotoCollection()
      });
      this.$('.modal-body').append(this.photoGallery.$el);
      this.$el.addClass('photo-gallery');
    },

    setPhotoCollection: function () {
      return new Backbone.Collection(this.model.get('photos'));
    },

    onShown: function () {
      this.photoGallery.initiateSlider(this.model.get('img_id'));
    }
  })
})
