import { namespace } from 'src/namespace';
import './table';

export default function() {};
namespace('Doctible.Views.ProviderPortal.Services', function(exports) {
  exports.EditTable = exports.Table.extend({
    el: 'table.edit-provider-medical-services',
    rowView: exports.EditRow,
  });
});
