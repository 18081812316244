import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.External', function (exports) {
  exports.Provider = Backbone.View.extend({
    el: '.modal-detail',

    events: {
      'click button.next': 'requestCheckout',
      'click button.back': 'goBack',
      'click button.request': 'submitCheckout',
      'click .request-link': 'showPreferredTimesSelector',
      'click .preferred-time': 'toggleNext'
    },

    initialize: function () {
      this.$('button.request').hide();
      this.preferredTimesSelector =
        new exports.PreferredTimesSelector({ model: this.model });
      if (this.$el.width() < 550) {
        this.calendar = this.preferredTimesSelector
        this.calendar.render(false);
        this.listenTo(this.calendar, 'responseReady', this.requestCheckout);
        // We remove this link so users can't change to preferred times selector
        this.$('.request-link').remove();
        this.showCalendar();
      } else {
        this.preferredTimesSelector.render(true);
        this.preferredTimesSelector.hide();
        if (this.model.get('first_available')) {
          this.calendar = new exports.Calendar({ model: this.model });
          this.listenTo(this.calendar, 'responseReady', this.requestCheckout);
          this.showCalendar();
        } else {
          this.showPreferredTimesSelector();
        }
      }
      this.setRatings();
    },

    showCalendar: function() {
      if (!this.calendar) return;
      if (this.selector) this.selector.hide();
      this.selector = this.calendar;
      this.selector.show();
      this.$('.request-link').show();
      this.$('.buttons button').hide();
    },

    showPreferredTimesSelector: function() {
      if (this.selector) this.selector.hide();
      this.selector = this.preferredTimesSelector;
      this.selector.show();
      this.$('.request-link').hide();
      this.toggleNext();
      if (this.calendar) {
        this.$('button.back').show();
      } else {
        this.$('button.back').hide();
      }
    },

    toggleNext: function() {
      this.$('button.next').toggle(this.selector.anySelected());
    },

    goBack: function() {
      if (this.checkout) {
        this.checkout.remove();
        delete this.checkout;
        this.$('button.request').hide();
        if (this.selector === this.calendar) this.showCalendar();
        else this.showPreferredTimesSelector();
      } else {
        this.showCalendar();
      }
    },

    submitCheckout: function() {
      this.checkout.submit();
    },

    requestCheckout: function() {
      var data = _.extend(this.selector.response(),
                          { provider_location_id: this.model.id });
      $.ajax({
        url: "/checkout_online",
        cache: false,
        data: data,
        success: _.bind(this.showCheckout, this)
      });
    },

    setRatings: function () {
      var ratings = this.$('.ratings');
      var providerRating = this.$('[data-provider-rating]').data('providerRating');;
      var rating = parseFloat(providerRating);
      if (rating > 0){
        ratings.raty({
          score: rating,
          space: false,
          starType: 'i',
          hints: ['', '', '', '', '' ],
          readOnly: true
        });
      }
    },

    showCheckout: function (html) {
      if (this.checkout) { return; }
      this.selector.hide();
      this.$('#checkout-container').append($(html));
      this.checkout = new exports.Checkout;
      this.listenTo(this.checkout, 'confirmed', this.showClose);
      this.$('button.request, button.back').show();
      this.$('button.next, .request-link').hide();
    },

    showClose: function() {
      this.$('.buttons button').hide();
      this.$('button.close-btn').show();
    }
  });
});
