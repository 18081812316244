import { namespace } from 'src/namespace';
import './requested_appointments/past_appointments.js'

export default function() {};
namespace('Doctible.Views.ProviderPortal', function (exports) {
  exports.PastAppointmentPage = Backbone.View.extend({
    el: '.provider_requested_appointments.past',

    initialize: function () {
      this.collection = new Doctible.Collections.PastAppointments;
      this.collection.on('sync', this.render, this);
      this.collection.fetch();
    },

    render: function () {
      this.pastAppointments = new Doctible.Views.ProviderPortal.RequestedAppointments.PastAppointments({collection: this.collection});
    },
  });

});
