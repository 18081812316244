import { namespace } from 'src/namespace';
import $ from 'jquery';

export default function() {};
namespace('Doctible.Support.Facebook', function (exports) {
  // exports.init = function (fbId) {
  //   (function(d, s, id){
  //     var js, fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) {return;}
  //     js = d.createElement(s); js.id = id;
  //     js.src = "//connect.facebook.net/en_US/sdk.js";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   }(document, 'script', 'facebook-jssdk'));

  //   window.fbAsyncInit = function() {
  //     FB.init({
  //       appId      : fbId,
  //       xfbml      : true,
  //       version    : 'v9.0'
  //     });
  //   };
  // };

  // exports.connect = function(callback) {
  //   FB.login(function(response) {
  //     if (response.status === 'connected') {
  //       var accessToken = response.authResponse.accessToken;
  //       callback(accessToken);
  //     } else {
  //       // TODO: Add a failure callback for front end
  //     }
  //   }, {
  //     // pages_show_list: showing a list pages the authenticating user manages
  //     // pages_read_user_content: get /ratings, this api gets a list of recommendations (reviews) on a Page.
  //     // pages_read_engagement: The pages_read_engagement permission allows your app to read content (posts, photos, videos, events) posted by the Page, read followers data (including name, PSID), and
  //     scope: 'pages_show_list, pages_read_user_content, pages_read_engagement'
  //   });
  // }
});
