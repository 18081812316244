import '../../../collections/providers/provider_location_hours'
import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.ProviderLocationHours', function(exports) {
  exports.Index = Doctible.Views.ProviderPortal.OfficeHours.Index.extend({
    el: '.provider_location_hours.index',

    collectionClass: Doctible.Collections.Providers.ProviderLocationHours,
  })
});
