import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Models', function (exports) {
  exports.Provider = Backbone.Model.extend({
    asHash: function () {
      return this.toJSON();
    },

    services: function() {
      return (new Backbone.Collection(this.get('services')));
    },

    paymentMethods: function() {
      return (new Backbone.Collection(this.get('payment_methods')));
    }
  });
});
