import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views', function (exports) {
  exports.PagingLinks = Backbone.View.extend({
    el: '.paging-links',
    events: {
      'click .paging-button': 'goToPage',
      'click .previous-button': 'goToPrevPage',
      'click .next-button': 'goToNextPage'
    },

    initialize: function () {
      this.collection.on('sync', this.render, this);

      var state = this.collection.currentState();
      var address = location.pathname + '?' + $.param(state);
      // TODO: Determine a better way to handle this so we avoid removing
      // necessary parameters (e.g. gclid for adwords)
      // Doctible.Support.History.replaceState(state, null, address);

      window.addEventListener('popstate', _.bind(function (event) {
        var page = event.state ? event.state.current_page : 1;
        this.collection.getPage(page);
      }, this));
    },

    render: function () {
      this.$el.html("");
      var state = this.collection.state;
      if (state && state.totalPages > 1){
        this._setLinks();
      }
    },

    updateHistory: function () {
      var state = this.collection.currentState();
      var address = location.pathname + '?' + $.param(state);
      Doctible.Support.History.pushState(state, null, address);
    },

    goToPage: function(event){
      this.collection.getPage(parseInt(event.target.text));
      this.updateHistory();
    },

    goToPrevPage: function(){
      this.collection.getPreviousPage();
      this.updateHistory();
    },

    goToNextPage: function(){
      this.collection.getNextPage();
      this.updateHistory();
    },

    _setLinks: function() {
      var state = this.collection.state;
      var numberOfPages = state.totalPages;
      var currentPage = state.currentPage;
      var totalPages = state.totalPages;

      var colDiv = $("<div class='col-centered'></div>");
      var paginator = $("<div id='paginator'></div>");

      var pagingList = $("<ul class='list-inline'></ul>");
      if (currentPage != 1) {
        var listItem = $("<li class='prev-next-button'></li>");
        var link = $("<a class='previous-button'>< Previous</a>");

        listItem.append(link);
        pagingList.append(listItem);
      }

      for(var i=0; i < numberOfPages; i++){
        var listItem = $("<li class='num-button'></li>");
        var link = $("<a class='paging-button'></a>");
        if (currentPage == (i+1)){
          listItem.addClass("active");
        }
        link.append(i+1);
        listItem.append(link);
        pagingList.append(listItem);
      }

      if (currentPage != totalPages) {
        var listItem = $("<li class='prev-next-button'></li>");
        var link = $("<a class='next-button'>Next ></a>");

        listItem.append(link);
        pagingList.append(listItem);
      }

      colDiv.append(paginator);
      paginator.append(pagingList);
      this.$el.append(colDiv);
    }
  });
});
