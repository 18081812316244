import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.Services', function(exports) {
  exports.Setup = Backbone.View.extend({
    el: '.services.setup',

    events: {
      'change input': 'checkValid',
      'click button': 'formSubmit'
    },

    initialize: function() {
      this.checkValid();
    },

    checkValid: function() {
      this.$('button').prop('disabled', this.$('input:checked').length === 0);
    },

    formSubmit: function() {
      this.$('form').submit();
    }
  });
});
