import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Search', function(exports) {
  exports.MapContainer = Backbone.View.extend({
    el: '.map-info-container',

    initialize: function() {
      var map = new exports.Map({collection: this.collection});
      map.$el.data('area', this.collection.params.area);
      new Doctible.Views.Pinnable({ el: this.$('.pinnable') });
    }
  })
});
