// A wrapper to ignore history.pushState in browsers that don't support it
import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Support.History', function (exports) {
  exports.pushState = function (state, title, url) {
    if (history && history.pushState) {
      history.pushState(state, title, url);
    }
  };

  exports.replaceState = function (state, title, url) {
    if (history && history.replaceState) {
      history.replaceState(state, title, url);
    }
  };
});
