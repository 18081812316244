import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Models', function(exports) {
  exports.OfficeHour = Backbone.Model.extend({
    urlRoot: '/api/provider_office_hours',

    hourText: function() {
      if (this.get('closed') === 'true') {
        return 'CLOSED';
      } else {
        return this.get('open_time') + ' - ' + this.get('close_time');
      }
    }
  })
});
