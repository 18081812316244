import BarChart from './bar_chart.js';
import DoughnutChart from './doughnut_chart.js';
import Map from './map.js';
import PreferredTimesSelector from './preferred_times_selector.js';

export default {
  BarChart,
  DoughnutChart,
  Map,
  PreferredTimesSelector,
};
