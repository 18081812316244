import { namespace } from 'src/namespace';

export default function () {}
namespace('Doctible.Models.Providers', (exports) => {
  exports.FacebookAccount = Backbone.Model.extend({
    urlRoot: '/api/providers/facebook_accounts',

    // listPages: function(token, callback) {
    //   var account = this;
    //   $.ajax({
    //     method: 'get',
    //     url: this.urlRoot + '/list_pages?token=' + token,
    //     success: (data) => {
    //       callback(data, account, token)
    //     }
    //   })
    // },

    publish(review, callback) {
      $.ajax({
        method: 'post',
        url: `${this.urlRoot}/publish`,
        data: { review_id: review.id },
        success: callback,
        error: (data) => {
          // Messenger().post({
          //   message: data.responseJSON['error'],
          //   type: 'error'
          // })
        },
      });
    },
  });
});
