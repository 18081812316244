// import 'messenger/build/js/messenger';
import { namespace } from 'src/namespace';

export default function () {}
namespace('Doctible.Views.ProviderPortal', (exports) => {
  exports.Body = Backbone.View.extend({
    el: 'body',

    events: {
      'change .location-select': 'setLocation',
      'click .provider-select a': 'setProvider',
    },

    initialize() {
      // Use Mustache.js style templating
      _.templateSettings.interpolate = /\{\{(.+?)\}\}/g;
      const viewsToInitialize = [
        exports.AppointmentPage,
        exports.PastAppointmentPage,
        exports.SchedulesPage,
        exports.Services.Index,
        exports.Services.Custom,
        exports.Services.EditPage,
        exports.Services.Setup,
        exports.MessagesPage,
        exports.BackgroundPage,
        exports.ProviderPhotoUploader,
        exports.OfficeHours.Index,
        exports.ProviderLocationHours.Index,
        exports.AppointmentReminders,
      ];
      const instantiateIfExists = function (view) {
        if (view && $(view.prototype.el).length > 0) {
          new view();
        }
      };
      _.each(viewsToInitialize, instantiateIfExists);
    },

    setLocation() {
      this.$('.location-selection form').submit();
    },

    setProvider(event) {
      event.preventDefault();
      const target = $(event.currentTarget);
      const form = this.$('.provider-select form');
      form.find('input').val(target.data('provider'));
      form.submit();
    },
  });

  // Alert
  // Messenger.options = {
  //   extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
  //   theme: 'flat',
  // };

  () => {
    $('[data-toggle="tooltip"]').tooltip();
  };
});
