import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Support.String', function (exports) {
  exports.isEmail = function(str) {
    // A very naive email check
    return str.indexOf('@') >= 0;
  },

  exports.isPhone = function(str) {
    return !!str.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
  }
});
