import { namespace } from 'src/namespace';
import moment from 'moment';

export default function() {};
namespace('Doctible.Views.Search', function(exports) {
  exports.AvailableTimes = Backbone.View.extend({
    className: 'available-times',

    events: {
      'click .left': 'previousDays',
      'click .right': 'nextDays'
    },

    initialize: function() {
      this.listenTo(this.collection, 'sync', this.render);
      this.dayOne = moment().add(1, 'days');
      this.dayTwo = moment().add(2, 'days');
      this.dayThree = moment().add(3, 'days');
      this.render();
    },

    fetchEvents: function() {
      this.collection.fetch({ data: {
        provider_location_id: this.model.get('id'),
        start: this.dayOne.format('YYYY-MM-DD'),
        end: this.dayTwo.clone().add(6, 'days').format('YYYY-MM-DD')
      }})
    },

    render: function() {
      this.$el.html(
        '<div class="days row">' +
          '<i class="fa left fa-chevron-left"></i>' +
          '<i class="fa right fa-chevron-right"></i>' +
        '</div>'
      );
      if (this.dayOne <= moment().add(1, 'days')) {
        this.$('.left').remove();
      }
      this.showEvents();
      this.delegateEvents();
    },

    previousDays: function() {
      this.dayOne.subtract(3, 'days');
      this.dayTwo.subtract(3, 'days');
      this.dayThree.subtract(3, 'days');
      this.fetchEvents();
    },

    nextDays: function() {
      this.dayOne.add(3, 'days');
      this.dayTwo.add(3, 'days');
      this.dayThree.add(3, 'days');
      this.fetchEvents();
    },

    showEvents: function() {
      this.groupedEvents = this.collection.groupedByDate();
      this.appendDay(this.dayOne);
      this.appendDay(this.dayTwo);
      this.appendDay(this.dayThree);
    },

    appendDay: function(momentDate) {
      var collection = new Doctible.Collections.AvailableEvents(
        this.groupedEvents[momentDate.format('YYYY-MM-DD')]
      );

      var modelClone = this.model.clone();
      modelClone.set({ selectedDate: momentDate.format('MM-DD-YYYY') });

      var day = new exports.AvailableTimesDay({
        collection: collection,
        model: modelClone
      });
      this.$('.days').append(day.$el);
      day.$('.date-name').text(momentDate.format('ddd'));
      day.$('.date').text(momentDate.format('MM-DD'));
    },
  });
});
