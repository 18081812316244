import { namespace } from 'src/namespace';
import './background/attributes.js';

export default function() {};
namespace('Doctible.Views.ProviderPortal', function (exports) {
  exports.BackgroundPage = Backbone.View.extend({
    el: '.backgrounds.setup',

    initialize: function () {
      this.collection = new Doctible.Collections.BackgroundAttributes;
      this.collection.on('sync', this.render, this);
      this.collection.fetch();
    },

    render: function () {
      this.education_attributes_collection = new Backbone.Collection(this.collection.where({attribute_key: 'education'}));
      this.affiliation_attributes_collection = new Backbone.Collection(this.collection.where({attribute_key: 'affiliation'}));
      this.certification_attributes_collection = new Backbone.Collection(this.collection.where({attribute_key: 'certification'}));
      this.award_attributes_collection = new Backbone.Collection(this.collection.where({attribute_key: 'award'}));

      this.education_attributes_collection.url = this.collection.url;
      this.affiliation_attributes_collection.url = this.collection.url;
      this.certification_attributes_collection.url = this.collection.url;
      this.award_attributes_collection.url = this.collection.url;

      new Doctible.Views.ProviderPortal.Background.Attributes({collection: this.education_attributes_collection, el: '.education-attributes' });
      new Doctible.Views.ProviderPortal.Background.Attributes({collection: this.affiliation_attributes_collection, el: '.affiliation-attributes' });
      new Doctible.Views.ProviderPortal.Background.Attributes({collection: this.certification_attributes_collection, el: '.certification-attributes' });
      new Doctible.Views.ProviderPortal.Background.Attributes({collection: this.award_attributes_collection, el: '.award-attributes' });
    }
  });
});
