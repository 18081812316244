import AppointmentModal from './appointment_modal.js';
import AppointmentRow from './appointment_row.js';
import BookedAppointmentRow from './booked_appointment_row.js';
import BookedAppointments from './booked_appointments.js';
import CheckInAppointmentModal from './check_in_appointment_modal.js';
import ConfirmAppointmentModal from './confirm_appointment_modal.js';
import ConfirmedAppointmentRow from './confirmed_appointment_row.js';
import ConfirmedAppointments from './confirmed_appointments.js';
import PastAppointmentRow from './past_appointment_row.js';
import PastAppointments from './past_appointments.js';

export default {
  AppointmentModal,
  AppointmentRow,
  BookedAppointmentRow,
  BookedAppointments,
  CheckInAppointmentModal,
  ConfirmAppointmentModal,
  ConfirmedAppointmentRow,
  ConfirmedAppointments,
  PastAppointmentRow,
  PastAppointments,
};
