import { namespace } from 'src/namespace';
import moment from 'moment';

export default function() {};
namespace('Doctible.Views.ProviderPortal', function(exports) {
  exports.AppointmentForm = Doctible.Views.Support.Form.extend({
    templateID: '#new-appointment-form',
    validation: {
      rules:
      {
        first_name: 'required',
        last_name: 'required',
        email: {
          required: true,
          email: true
        },
        phone_number: 'required',
        start_date: 'required',
        end_date: {
          required: true,
          greaterThan: '[name=start_date]'
        }
      },
      messages:
      {
        first_name: 'Required',
        last_name: 'Required',
        email: {
          required: 'Email is required',
          email: 'Invalid email'
        },
        phone_number: 'A phone number is required',
        start_date: 'A start time is required',
        end_date: {
          required: 'An end time is required',
          greaterThan: 'The end time must be after the start time'
        }
      },
    },

    events: {
      'change [name=start_date]': 'updateEndDate'
    },

    render: function() {
      var template = _.template($(this.templateID).text());
      this.$el.html(template())

      this.$('[name=start_date], [name=end_date]').datetimepicker();
      this.$('[name=phone_number]').mask('(000) 000-0000');
      Doctible.Support.addCustomFormValidators();
    },

    updateEndDate: function() {
      var dateFormat = 'YYYY/MM/DD HH:mm'
      var endDate = moment(this.$('[name=start_date]').val(), dateFormat)
                    .add(30, 'minutes');
      this.$('[name=end_date]').datetimepicker({
        value: endDate.format(dateFormat)
      });
    },

    setupSelect: function() {
      var select = this.$('select.services-select');
      // Override the reset function so we don't add the extra option, instead
      // defaulting to the first service
      var serviceSelect = Doctible.Views.ServicesSelect.extend({
        reset: function () {
          this.$('option').remove();
        },
        optionFromModel: function (model) {
          return $('<option>', {
            text: model.get('name') + ' - ' + model.get('price'),
            val: model.get('id')
          });
        },
      });
      new serviceSelect({ el: select });
    },

    submitForm: function() {
      this.model.set(this.formData());
      this.model.save();
    },

    removeDatepickers: function() {
      this.$('[name=start_date], [name=end_date]').datetimepicker('destroy');
    }
  });
});
