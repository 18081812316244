import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal', function (exports) {
  exports.ProgressBar = Backbone.View.extend({
    el: '#uploader-progress',

    show: function() {
      this.$el.addClass('visible');
    },

    updateWidth: function(width) {
      this.$('.progress-bar').width(width + '%');
    },

    hide: function() {
      this.$el.removeClass('visible');
      this.updateWidth(0);
    }
  });
});
