import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Search', function(exports) {
  exports.AvailableTimesEvent = Backbone.View.extend({
    className: 'event',

    events: {
      'click': 'goToCheckout'
    },

    initialize: function() {
      this.render();
    },

    render: function() {
      this.$el.html(this.model.formattedStart());
    },

    goToCheckout: function() {
      // this.provider is set after instantiation
      var eventData = {
        ei: this.model.get('id'),
        provider_location_id: this.provider.get('id')
      };


      Doctible.Support.startCheckoutSession(_.extend(eventData, {
        treatment: this.provider.firstService().id,
        insurance: this.model.get('insurance_param')
      }));
    },
  });
});
