import { namespace } from 'src/namespace';
import './delete_event_modal.js'

export default function() {};
namespace('Doctible.Views.ProviderPortal.Schedules', function(exports) {
  exports.Event = Backbone.View.extend({
    events: {
      'click .delete-event': 'deleteEvent',
      'click': 'handleClick'
    },

    initialize: function() {
      this.render();
      this.delegateEvents();
    },

    render: function() {
      this.$('.fc-title').html(this.titleContent());
    },

    titleContent: function() { return this.title(); },

    title: function() {
      return 'New Event';
    },

    handleClick: function() {
      // If the provider appointment has been created
      if (this.providerAppointment) {
        // and the modal is available to show, then we show it. Otherwise, we
        // wait for a successful fetch
        if (this.modal) { this.modal.show() }
      } else {
        this.newEventModal();
      }
    },

    deleteEvent: function(event) {
      event.stopPropagation();
      this.deleteEventModal = this.deleteEventModal ||
        new exports.DeleteEventModal({model: this.model});
      this.deleteEventModal.show();
    },

    newEventModal: function() {
      var appointmentId = this.model.get('appointmentId')
      if (!appointmentId) { return; }
      this.providerAppointment =
        new Doctible.Models.ProviderAppointment({id: appointmentId});
      this.providerAppointment.fetch({
        success: _.bind(function(model) {
          this.modal = new exports.EventModal({model: model});
          this.modal.show()
        }, this)
      })
    }
  })
});
