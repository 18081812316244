import { namespace } from 'src/namespace';
import moment from 'moment';

export default function() {};
namespace('Doctible.Views.External', function (exports) {
  exports.Calendar = Backbone.View.extend({
    el: '#calendar-selector',

    events: {
      'click .event': 'setEvent',
      'click button.right': 'nextDays',
      'click button.left': 'previousDays'
    },

    DAY_INCREMENT: 5,

    initialize: function() {
      this.today = moment();
      this.$('.btn.left').hide();
      this.collection = new Doctible.Collections.AvailableEvents();
      this.listenTo(this.collection, 'sync', this.render);
      this.startDate = this.today.clone();
      this.fetchEvents();
    },

    fetchEvents: function() {
      this.$('.btn.left').toggle(this.startDate.isAfter(this.today, 'day'));
      this.endDate = this.startDate.clone().add(this.DAY_INCREMENT,'days');
      this.collection.fetch({ data: {
        provider_location_id: this.model.get('id'),
        start: this.startDate.format('YYYY-MM-DD'),
        end: this.endDate.format('YYYY-MM-DD')
      }});
    },

    nextDays: function() {
      this.startDate.add(this.DAY_INCREMENT, 'days');
      this.fetchEvents();
    },

    previousDays: function() {
      this.startDate.subtract(this.DAY_INCREMENT, 'days');
      this.fetchEvents();
    },

    render: function() {
      var endDate = this.endDate.clone();
      var currentDate = this.startDate.clone();
      this.$('.calendar-days').empty();
      do {
        this.renderDay(currentDate);
        currentDate.add(1, 'day');
      } while(currentDate.format('YYYY-MM-DD') != endDate.format('YYYY-MM-DD'));
      this.delegateEvents();
    },

    renderDay: function(date) {
      // Using a model to send the date to CalendarDay view.
      var model = new Backbone.Model({ date: date });
      var forDay = this.collection.groupedByDate()[date.format('YYYY-MM-DD')];
      var dayCollection = new Backbone.Collection(forDay);
      var day = new exports.CalendarDay({ collection: dayCollection, model: model });
      this.$('.calendar-days').append(day.$el);
    },

    setEvent: function(event) {
      this.selected = $(event.currentTarget).data('event-id');
      this.trigger('responseReady');
    },

    response: function() {
      return { provider_scheduled_event_id: this.selected };
    },

    show: function() {
      this.$el.show();
    },

    hide: function() {
      this.$el.hide();
    }
  });
});
