import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Models', function (exports) {
  exports.Specialty = Backbone.Model.extend({
    url : function() {
      if (this.id)
      {
        return '/api/specialties/' + this.id;
      }
      else{
        return '/api/specialties';
      }
    }
  });
});
