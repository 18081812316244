import { namespace } from 'src/namespace';
import './event.js'

export default function() {};
namespace('Doctible.Views.ProviderPortal.Schedules', function(exports) {
  exports.CheckedInEvent = exports.Event.extend({
    titleContent: function() {
      return this.title() + '<span class="popovericon view-event" title="Edit"></span>';
    },

    viewEvent: function() {
      var eventModal = exports.EventModal.extend({templateID: '#view-event-modal'});
      this.modal = this.modal || new eventModal({model: this.model});
      this.modal.show();
    },

    handleClick: function() {
      this.viewEvent();
    }
  });
});
