import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views', function(exports) {
  exports.Pinnable = Backbone.View.extend({
    el: '.pinnable',

    initialize: function() {
      this.pinnableContainer = $(this.$el.data('pinnable-container'));
      // Set the width inline to avoid width changing when positioning changes
      this.$el.width(this.$el.width());
      this.originalTop = this.$el.offset().top;
      $(window).on('scroll', _.bind(this.pin, this));
      $(window).on('resize', _.bind(this.adjustWidth, this));
    },

    adjustWidth: function() {
      // Handling on resize so map width is updated when necessary. We need to
      // remove positioning and inline width to determine what width the
      // element has in normal DOM flow.
      this.$el.removeClass('pinned').width('');
      this.$el.width(this.$el.width()).addClass('pinned')
    },

    pin: function() {
      // TODO: Refactor to allow height to be changed for different pinnable
      // elements
      var pinHeight = $('nav').height() + 10;
      var scrollTop = $(window).scrollTop() + pinHeight >= this.originalTop;
      this.$el.toggleClass('pinned', scrollTop)
        .css('top', scrollTop ? pinHeight + 'px' : '');
      // Removing class bottom to recheck position for bottom pinning check
      this.$el.removeClass('bottom')
      var pinnableBottom = this.$el.outerHeight() + this.$el.offset().top;
      var containerBottom = this.pinnableContainer.outerHeight() +
                            this.pinnableContainer.offset().top;
      if (pinnableBottom >= containerBottom) {
        this.$el.addClass('bottom').css('top', '');
      }
    }
  });
});
