import { namespace } from 'src/namespace';
import moment from 'moment';

export default function() {};
namespace('Doctible.Views.AppointmentWidget', function(exports) {
  exports.Calendar = Backbone.View.extend({
    DAYS_TO_SHOW: 5,

    events: {
      'click .left': 'previousDays',
      'click .right': 'nextDays',
      'click .preferred-time': 'togglePreferred',
      'click .request-appointment': 'submitPreferred',
      'click .before-first-available button': 'goToFirstAvailable'
    },

    // We extend this Calendar object to override this for the external widget
    // in order to avoid fetching events
    PREFERRED_ONLY: false,

    DATA_DATE_FORMAT: 'YYYY/MM/DD',

    initialize: function() {
      this.collection =
        this.collection || new Doctible.Collections.AvailableEvents();
      this.listenTo(this.collection, 'sync', this.updateEvents);
      this.setFirstAvailable();
      this.widgetStartDay = moment().startOf('day');
      this.render();
      this.$('.days').css('left', 0);
      this.$('.day').first().click();
    },

    setFirstAvailable: function() {
      var firstAvailable = this.model.get('next_available_request_date');
      this.firstAvailable = moment(firstAvailable).startOf('day');
      this.$('.first-available-date')
        .text(this.firstAvailable.format('ddd, MMM D'));
    },

    render: function(strategy) {
      var endDay = this.widgetStartDay.clone()
                   .add(this.DAYS_TO_SHOW - 1, 'days')
      var headerText = [
        this.widgetStartDay.format('MMMM D'),
        endDay.format('MMMM D')
      ].join(' - ');
      this.$('.calendar-header span').text(headerText);
      this.$('.day').off('click');
      var days = _.map(_.range(this.DAYS_TO_SHOW), _.bind(this.setDay, this));
      var daysEl = this.$('.days')
      this.$('.available-times-container, .available-times, ' +
             '.preferred-times, .request-appointment, .before-first-available').hide();
      if (strategy === 'prepend') {
        _.each(days.reverse(), function(dayEl) { daysEl.prepend(dayEl); });
      } else {
        _.each(days, function(dayEl) { daysEl.append(dayEl); });
      }
      this.$('.left').toggle(!this.atCurrentDay());
    },

    setDay: function(day) {
      var startDay = this.widgetStartDay.clone();
      var date = startDay.add(day, 'days');
      var width = this.calendarWidth() / this.DAYS_TO_SHOW;
      // Setting the data attribute with attr so selectors can find it
      var dayEl = $('<div>', {
        class: 'day',
        style: 'width: ' + width + 'px'
      }).attr('data-date', date.format(this.DATA_DATE_FORMAT));
      if (_.contains(this.model.get('closed_days'), date.day()))
        dayEl.addClass('closed');
      dayEl.append($('<div>', { class: 'wday', text: date.format('ddd') }));
      dayEl.append($('<div>', { class: 'date', text: date.format('D') }));
      dayEl.click(_.bind(this.selectDay, this));
      return dayEl;
    },

    goToFirstAvailable: function() {
      var firstAvailable = this.firstAvailable.format(this.DATA_DATE_FORMAT);
      var diff = this.firstAvailable.diff(this.currentDate, 'days');
      var daysToAdd = Math.floor(diff / this.DAYS_TO_SHOW) * this.DAYS_TO_SHOW;
      if (daysToAdd > 0) {
        // Set the widget start day so that the set of days includes the first
        // available
        this.widgetStartDay.add(daysToAdd, 'days');
        this.$('.day').remove();
        this.render();
      }
      this.$('.day[data-date="' + firstAvailable + '"]').click();
      this.adjustArrow();
    },

    selectDay: function(event) {
      this.$('.closed-day').hide();
      this.$('.day').removeClass('selected');
      var selectedDay = $(event.currentTarget);
      var day = selectedDay.addClass('selected');
      this.currentDate = moment(day.data('date'), this.DATA_DATE_FORMAT);
      this.adjustArrow();
      if (this.beforeFirstAvailable()) {
        this.showFirstAvailableButton();
      } else if (selectedDay.hasClass('closed')) {
        this.setClosed();
      } else {
        this.fetchEvents(this.currentDate);
      }
    },

    beforeFirstAvailable: function() {
      return this.currentDate.isBefore(this.firstAvailable);
    },

    showFirstAvailableButton: function() {
      this.resetTimes();
      this.$('.available-times-container, .before-first-available').show();
    },

    setClosed: function() {
      this.$('.preferred-times, .available-times').hide();
      this.$('.available-times-container, .closed-day').show();
    },

    togglePreferred: function(event) {
      $(event.currentTarget).toggleClass('selected');
      var selected = this.$('.preferred-time.selected');
      this.$('.request-appointment').toggle(selected.length > 0)
    },

    adjustArrow: function() {
      var selected = this.$('.selected');
      // Distance between side of calendar and selected day
      var selectedOffset = selected.offset().left - this.$el.offset().left;
      // Single margin space necessary to center arrow on selected day
      var arrowMarginToCenter =
        (selected.width() - this.$('.available-times-arrow').outerWidth()) / 2;
      this.$('.available-times-arrow')
        .css('left', selectedOffset + arrowMarginToCenter);
    },

    calendarWidth: function() {
      return this.$('.calendar').width();
    },

    previousDays: function() {
      if (this.atCurrentDay()) return;
      this.widgetStartDay.subtract(this.DAYS_TO_SHOW, 'days');
      var daysEl = this.$('.days');
      var calendarWidth = this.calendarWidth() + 'px';
      daysEl.addClass('notransition').css('left', '-' + calendarWidth);
      this.render('prepend');
      this.$('.days').removeClass('notransition').css('left', 0);
      var resetDays = _.bind(function() {
        this.$('.day').slice(5, 10).remove();
        this.$('.day').first().click();
      }, this);
      setTimeout(resetDays, 300);
    },

    atCurrentDay: function() {
      return this.widgetStartDay.isSameOrBefore(moment().startOf('day'));
    },

    nextDays: function() {
      this.widgetStartDay.add(this.DAYS_TO_SHOW, 'days');
      this.$('.days').css('left', 0);
      this.render();
      this.$('.days').css({ left: '-' + this.calendarWidth() + 'px' });
      var resetDays = _.bind(function() {
        this.$('.days').addClass('notransition').css({ left: '0' });
        this.$('.day').slice(0, 5).remove();
        this.$('.day').first().click();
        setTimeout(function() {
          this.$('.days').removeClass('notransition');
        }, 10);
      }, this);
      setTimeout(resetDays, 300);
    },

    resetTimes: function() {
      this.$('.listed-times').text('');
      this.$('.preferred-time').removeClass('selected');
      this.$('.available-times, .preferred-times, .request-appointment, .before-first-available').hide()
    },

    fetchEvents: function(date) {
      this.resetTimes();
      this.$('.available-times-container').show()
        .find('.date').text(date.format('MMMM D'));
      if (this.PREFERRED_ONLY) return this.showPreferred();
      this.collection.fetch({ data: {
        provider_location_id: this.model.get('id'),
        start: date.format('YYYY-MM-DD'),
        end: date.format('YYYY-MM-DD')
      }})
    },

    updateEvents: function() {
      var selectedDate = moment(this.$('.day.selected').data('date'), this.DATA_DATE_FORMAT);
      var models =
        this.collection.groupedByDate()[selectedDate.format('YYYY-MM-DD')];
      models ? this.showAvailable(models) : this.showPreferred();
    },

    showAvailable: function(models) {
      this.$('.available-times').show();
      var times = this.$('.listed-times');
      var calendar = this;
      _.each(models, function(model) {
        var event = $('<div>', {
          class: 'available-event',
          text: model.formattedStart()
        });
        event.click(_.bind(calendar.selectEvent, calendar, model.id))
        times.append(event)
      });
      var events = times.find('.available-event');
      if (events.length > 9) {
        events.slice(9).hide();
        var button = $('<button>', { class: 'btn show-more', text: 'Show More' });
        times.append(button);
        button.click(function() {
          events.show();
          button.remove();
        });
      }
    },

    showPreferred: function() {
      this.$('.preferred-times').show();
    },

    selectEvent: function(id) {
      this.trigger('eventSelected', { ei: id });
    },

    submitPreferred: function() {
      this.trigger('eventSelected', {
        preferred_times: this.preferredTimes(),
        date: this.currentDate.format('ddd MMM D YYYY')
      })
    },

    preferredTimes: function () {
      var selectedTimes = this.$('.preferred-time.selected');
      return _.map(selectedTimes, function(el) {
        return $(el).data('preferred-time');
      });
    }
  })
});
