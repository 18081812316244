import { namespace } from 'src/namespace';
import './support/street_view';
import './popup';
import starOn from "../../../../assets/images/star-on.png";
import starOff from "../../../../assets/images/star-off.png";

export default function() {};
namespace('Doctible.Views', function (exports) {
  exports.LocationProfile = Backbone.View.extend({
    el: '.locations.show',

    initialize: function () {
      var locationJSON = this.$('[data-location-json]').data('location-json');
      this.model = new Doctible.Models.Location(locationJSON);

      if (!locationJSON.cover_photo) {
        this._setPageHeader(locationJSON.page_header);
      }
      this._setRatings();
      this._createAppointmentWidget();
      new Doctible.Views.Pinnable({ el: this.$('.location-info-container') });
    },

    _setRatings: function () {
      var ratings = this.$('.main-columns .ratings');

      _.each(ratings, function(s){
        var rating = $(s).data('rating');
        $(s).raty({
          score: rating, hints: [ '', '', '', '', '' ],
          readOnly: true,
          starOn: starOn,
          starOff: starOff,
        });
      })
    },

    _setPageHeader: function(headerType) {
      if (headerType == 'map_view') {
        new Doctible.Views.Shared.Map({
          el: this.$('.map-view'),
          model: this.model
        });
      } else {
        new Doctible.Views.Support.StreetView({
          el: this.$('.street-view'),
          model: this.model
        });
      }
    },

    _createAppointmentWidget: function() {
      var appt = this.$('.book-appointment');
      var widgetType = appt.data('appointment-type');
      var urlName = appt.data('url');

      appt.doctiblePopup({
        id: urlName,
        type: widgetType,
        buttonText: 'Book Appointment',
        buttonColor: '#16A2AA'
      });
    }
  })
});
