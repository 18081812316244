import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.External', function (exports) {
  exports.Checkout = Backbone.View.extend({
    el: '#checkout-view',

    events: {
      'click #edit_appointment': 'editAppointment'
    },

    initialize: function () {
      this.$("form:not(.filter) :input:visible:enabled:first").focus();
      this.$("input#phone").mask("(000) 000-0000");
      this.setValidations();
    },

    editAppointment: function () {
      this.$el.empty();
      this.trigger('select-appointment');
    },

    submit: function (event) {
      this.$('form').submit();
    },

    setValidations: function() {
      this.$('form').validate({
        rules: {
          "user_checkout[patient_data][first_name]": { required: true },
          "user_checkout[patient_data][last_name]": { required: true },
          "user_checkout[patient_data][email]": {
            required: true,
            email: true
          },
          "user_checkout[patient_data][phone_number]": {
            required: true,
            rangelength: [14,14]
          }
        },
        messages: {
          "user_checkout[patient_data][first_name]": "First Name is required",
          "user_checkout[patient_data][last_name]": "Last Name is required",
          "user_checkout[patient_data][email]": {
            required: "Email is required",
            email: "Invalid email"
          },
          "user_checkout[patient_data][phone_number]": {
            required: "Phone # required",
            rangelength: "Invalid phone #"
          }
        },
        submitHandler: _.bind(function(form) {
          var formEl = $(form);
          // this.$('form')[0].submit();
          var csrfToken = $('meta[name="csrf-token"]').attr('content');
          $.ajax({
            url: formEl.attr('action'),
            type: 'POST',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('X-CSRF-Token', csrfToken)
            },
            data: formEl.serialize(),
            success: _.bind(this.showResult, this)
          });
          this.$el.empty();
        }, this)
      });
    },

    showResult: function(html) {
      this.trigger('confirmed');
      this.$el.html(html);
    }
  })
})
