import { namespace } from 'src/namespace';
import './support/smooth_scroll_link.js';
import starOn from "../../../../assets/images/star-on.png";
import starOff from "../../../../assets/images/star-off.png";
import starHalf from "../../../../assets/images/star-half.png";

export default function() {};
namespace('Doctible.Views', function (exports) {
  exports.ProviderProfile = Backbone.View.extend({
    el: '.providers.show',

    events: {
      'click .all-services button': 'launchAvailabilityModal',
      'click .doctor-images img, .doctor-images .overlay': 'showPhotoGallery',
      'click .contact-provider-btn': 'launchContactModal',
      'click .show-number-btn': 'showNumber',
      "click [href=\'#mobile-services\']": 'showServicesPane',
      'shown.bs.tab .mobile-tabs .book': 'showMobileCalendar',
      'click .book-appointment': 'showAppointmentWidget'
    },

    initialize: function () {
      var providerJSON = this.$('[data-provider-json]').data('provider-json');
      this.model = new Doctible.Models.Provider(providerJSON);
      this.appointments = new Doctible.Views.Provider.RequestedAppointments({
        el: this.$('.appointment-times'),
        model: this.model
      });
      new Doctible.Views.Shared.Map({
        el: this.$('.map'),
        model: this.model
      });
      new Doctible.Views.Support.SmoothScrollLink({el: this.$('[href^=\\#]')});

      this._setRatings();

      if (!this.model.get('hide_req_appt') && this.$('.appointment-widget').length > 0){
        this.appointmentWidget = new exports.AppointmentWidget.Show({
          el: this.$('.appointment-widget'),
          model: this.model.set({
            insurance: this.$('[data-insurance]').data('insurance'),
            sei: this.$('[data-sei]').data('sei')
          })
        });
        this.appointments.firstAvailableTime.on('sync', function() {
          var day = this.appointments.firstAvailableTime.get('day');
          if (!day) {
            this.$('.tab-link.book').hide();
          }
        }, this);
      }

      this._cacheMenuItems();
      // Handling on resize so menu width is updated when necessary
      $(window).on('scroll', _.bind(this.highlightMenu, this));
      $(window).on('scroll', _.bind(this.toggleAppointmentBtn, this));
    },

    _setRatings: function () {
      var ratings = this.$('.provider-info .ratings');
      var rating = parseFloat(this.model.get('average_rating'));
      ratings.raty({
        score: rating, hints: [ '', '', '', '', '' ],
        readOnly: true,
        starOn: starOn,
        starOff: starOff,
        starHalf: starHalf
      });
    },

    launchAvailabilityModal: function (event) {
      var serviceJSON = $(event.currentTarget).data('service');
      this.model.set({
        selected_service_id: serviceJSON.id,
        description: serviceJSON.description,
        display_price: serviceJSON.display_price
      });
      new exports.AppointmentAvailabilityModal({ model: this.model });
    },

    launchContactModal: function() {
      this._contactModal =
        this._contactModal ||
        new exports.Provider.ContactModal({model: this.model});
      this._contactModal.show();
    },

    showServicesPane: function() {
      this.$('.service-tab').tab('show');
    },

    showPhotoGallery: function (event) {
      this.model.set({ img_id: $(event.target).data('img-id') })
      var modal = new exports.Provider.PhotoGalleryModal({model: this.model});
      modal.show();
    },

    showMobileCalendar: function(event) {
      this.appointments.showMobileCalendar();
    },

    _cacheMenuItems: function(){
      this.menuItems = this.$('.main-menu a')
      // Caches the top offset of menu items
      this.scrollItems = _.map(this.menuItems, function(item){
        var itemId = $(item).attr("href");
        return [itemId, $(itemId).offset().top];
      });
    },

    highlightMenu: function() {
      var fromTop = $(window).scrollTop() +
                    this.$('.menu-row').outerHeight() +
                    this.$('nav').outerHeight();
      var items = _.filter(this.scrollItems, function(item){
        return fromTop >= item[1];
      });
      // With no matching menu items, we set the first to be active
      var id = (_.last(items) || _.first(this.scrollItems))[0];
      this.menuItems
          .parent().removeClass("active")
          .end().filter("[href=\\"+id+"]").parent().addClass("active");
    },

    showNumber: function(){
      this.$('.show-number-btn').text(this.model.get('primary_phone'));
    },

    showAppointmentWidget: function() {
      $(window).scrollTop(this.widgetTop());
    },

    toggleAppointmentBtn: function() {
      if (this.appointmentWidget) {
        var windowTop = $(window).scrollTop() + $(window).height();
        var isWidgetOutOfView = windowTop < this.widgetTop();
        this.$('.book-appointment.btn').toggleClass('showing', isWidgetOutOfView);
      }
    },

    widgetTop: function() {
      var navHeight = this.$('nav').outerHeight();
      return this.appointmentWidget.$el.offset().top - navHeight;
    }
  })
});
