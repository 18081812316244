import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.Services', function(exports) {
  exports.AddServicesModal = Doctible.Views.Support.Modal.extend({
    additionalEvents: {
      'click button.add': 'addServices'
    },

    postInitialize: function() {
      this.collection.each(_.bind(function(service) {
        var ms_id = service.get('medical_service_id');
        this.$('input[value=' + ms_id +']').prop('checked', true)
          .prop('disabled', true)
      }, this))
    },

    modalHeaderContent: function() {
      this.$('.modal-header').remove();
    },

    modalBodyContent: function() {
      return _.template($('#add-services-modal').text())();
    },

    onHidden: function() {
      this.remove();
    },

    addServices: function() {
      this.collection.add(this.selectedServices());
      this.collection.save({ success: _.bind(function() {
        this.collection.fetch();
        this.hide();
      }, this) });
    },

    selectedServices: function() {
      return _.map(this.$('input:checked:enabled'), function(input) {
        var checkedInput = $(input);
        return { medical_service_id: checkedInput.val() };
      });
    }
  });
});
