import { namespace } from 'src/namespace';
import 'selectize';
import '../../support/string.js'

export default function() {};
namespace('Doctible.Views.ProviderPortal', function(exports) {
  exports.ImportModal = Doctible.Views.Support.TemplateModal.extend({
    templateID: '#import-modal',

    events: {
      'click .add-to-list': 'addToList'
    },

    smsContactMethod: 'text',

    addToList: function() {
      var patients = this.$('input#contacts').val().split(',');
      _.each(patients, this.addPatient, this);
      this.postAdd();
      this.hide();
    },

    postAdd: function() {},

    addPatient: function(patient) {
      patient = patient.trim();
      if (patient.length === 0) return;
      var contact_by = this.getContactMethod(patient)
      if (!contact_by) {
        this.invalidContacts = this.invalidContacts || [];
        this.invalidContacts.push(patient);
        return;
      }
      this.create(patient, contact_by);
    },

    getContactMethod: function(patient) {
      var strings = Doctible.Support.String
      if (strings.isEmail(patient)) {
        return 'email';
      } else if (strings.isPhone(patient)) {
        return this.smsContactMethod;
      } else return;
    },

    create: function(patient, contact_by) {
      this.collection.createNewContact({
        name: null,
        contact: patient,
        contact_by: contact_by
      });
    },

    postRender: function() {
      this.$('input#contacts').selectize({
        plugins: ['remove_button', 'restore_on_backspace'],
        delimiter: ',',
        persist: false,
        maxItems: 25,
        create: function(input) {
          return {
            value: input,
            text: input
          }
        }
      });
    }
  });
});
