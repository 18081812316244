import Base from './base.js';
import Distance from './distance.js';
import Gender from './gender.js';
import Insurance from './insurance.js';

export default {
  Base,
  Distance,
  Gender,
  Insurance,
};
