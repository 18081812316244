import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views', function (exports) {
  exports.SpecialtiesSelect = exports.SelectInput.extend({
    collection_class: Doctible.Collections.Specialties,
    css_class: 'specialty',

    reset: function () {
      this.$('option, optgroup').remove();
      this.$el.append($('<option>'))
      var popular = $('<optgroup>', {
                       label: 'Popular Specialties',
                       class: 'popular'}
                     )
      var other = $('<optgroup>', {
                     label: 'All Specialties',
                     class: 'other'}
                   )
      this.$el.append(popular).append(other)
    },

    appendOptions: function (model) {
      var group = model.get('priority') ? this.$('.popular') : this.$('.other');
      group.append(this.optionFromModel(model))
    },

    postInitialize: function () {
      // When initial collection isn't set from data attribute
      if (!this.$el.data('collection')) { this.collection.fetch() };
    }
  });
});
