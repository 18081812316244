import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.Services', function(exports) {
  exports.Table = Backbone.View.extend({
    el: 'table.provider-medical-services',
    rowView: exports.Row,

    initialize: function() {
      this.listenTo(this.collection, 'sync', this.render);
      this.collection.fetch();
    },

    render: function() {
      this.$('tbody tr').remove();
      this.collection.each(function(model) {
        var row = new this.rowView({
          model: model,
          collection: this.collection
        }, this);
        row.$el.data('modelId', model.id);
        this.$('tbody').append(row.$el);
      }, this);
      this.$('tbody').sortable({
        axis: 'y',
        placeholder: "ui-sortable-placeholder",
        update: _.bind(this.savePositions, this)
      });
    },

    savePositions: function() {
      var positions = _.map(this.$('tbody tr'), function(value, index) {
        return { 'id': $(value).data('modelId'), 'position': index };
      });
      var positionsModel = Backbone.Model.extend({
        url: '/api/provider_medical_services/positions'
      })
      var model = new positionsModel({ positions: positions });
      model.save();
    }
  });
});
