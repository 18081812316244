import AppointmentWidget from './appointment_widget.js';
import RequestedAppointments from './requested_appointments.js';
import Calendar from './calendar.js';
import ContactForm from './contact_form.js';
import ContactModal from './contact_modal.js';
import PhotoGallery from './photo_gallery.js';
import PhotoGalleryModal from './photo_gallery_modal.js';
import ServiceSelectModal from './service_select_modal.js';
import ServicesSelect from './services_select.js';

export default {
  AppointmentWidget,
  RequestedAppointments,
  Calendar,
  ContactForm,
  ContactModal,
  PhotoGallery,
  PhotoGalleryModal,
  ServiceSelectModal,
  ServicesSelect,
};
