import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Search.Filters', function(exports) {
  exports.Insurance = exports.Base.extend({
    el: '.filter-section.insurance',
    paramName: 'insurance',

    // We bind the change event to the set handler after setting the initial
    // value to avoid handling the initially triggered change
    events: {
      'click label.filter': 'toggle',
    },

    initialize: function() {
      this.$('select').select2({ minimumResultsForSearch: Infinity });
    },

    bindEvents: function() {
      this.$('select').off('change.filter');
      this.$('select').on('change.filter', _.bind(this.set, this));
    },

    setInputValue: function(value) {
      this.$('select').select2('val', value)
      this.bindEvents();
    }
  })
});
