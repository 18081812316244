import { namespace } from 'src/namespace';
import 'jquery-datetimepicker'

export default function() {};
namespace('Doctible.Views.ProviderPortal.Schedules', function(exports) {
  exports.NewEventModal = Doctible.Views.Support.Modal.extend({
    postRender: function() {
      this.renderedTemplate = _.template($('#create-event-modal').text())();
      this.$('.modal-header').remove();
    },

    postInitialize: function() {
      this.$('.datetimepicker').datetimepicker({ step: 30 });
      this.$('.create').click(_.bind(this.createEvent, this));
    },

    modalBodyContent: function() {
      return this.renderedTemplate;
    },

    setTimes: function(start, end) {
      this.$('.datetimepicker').first()
        .datetimepicker({ value: start.format('YYYY/MM/DD HH:mm') })
      this.$('.datetimepicker').last()
        .datetimepicker({ value: end.format('YYYY/MM/DD HH:mm') })
    },

    createEvent: function() {
      var model = new Doctible.Models.NewEvent;
      model.set({
        start: this.$('[name=start]').val(),
        end: this.$('[name=end]').val(),
        repeating_frequency: this.$('[name=repeating_frequency]').val()
      })
      model.save();
      this.hide();
    },

    onHidden: function() {
      this.$('.datetimepicker').datetimepicker('destroy');
      this.remove();
    }
  })
});
