import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal', function(exports) {
  exports.MessagesPage = Backbone.View.extend({
    el: '.provider_messages.index',

    events: {
    },

    initialize: function() {

    }
  });
});
