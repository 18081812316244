import '../support/modal.js';
import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Provider', function (exports) {
  exports.ContactModal = Doctible.Views.Support.Modal.extend({
    id: 'contact-modal',

    postRender: function() {
      this.form = new exports.ContactForm({model: this.model});
      this.form.on('submit', this.showThanks, this)
    },

    modalHeaderContent: function() {
      return 'Contact ' + this.model.get('provider_name');
    },

    modalBodyContent: function() {
      return this.form.$el;
    },

    modalFooterContent: function() {
      var button = this.form.$('button')
      // Since we're moving the button outside of the view scope, we rebind
      // click event
      button.on('click', _.bind(this.form.submit, this.form));
      return button;
    },

    showThanks: function() {
      this.form.$el.replaceWith(
        "<p>Thanks for the message! We'll be in touch shortly.</p>"
      );
      this.$('.modal-footer').remove();
      setTimeout(_.bind(this.hide, this), 2000);
    },

    onShown: function() {
      this.form.setFocus();
    },

    onHide: function() {
      this.form.removeFocus();
    }
  });
});
