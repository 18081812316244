import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views', function (exports) {
  exports.ServicesSelect = exports.SelectInput.extend({
    collection_class: Doctible.Collections.MedicalServiceLabels,
    css_class: 'service-select',

    update: function (specialty_id) {
      this.collection.on('sync', this.initializeService, this);
      if ( this.$el.data('specialty_id') === specialty_id ) { return };
      this.collection.fetch({ data: { specialty_id: specialty_id } });
      this.$el.data('specialty_id', specialty_id);
      this.$el.prop('disabled', false);
    },

    initializeService: function(){
      if (this.$('option:selected[value]').length == 0 && this.collection.length > 0){
        var firstService = this.collection.at(0);
        this.$el.select2('val', firstService.get('id'))
      }
    }
  });
});
