import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.Services', function(exports) {
  exports.AddServiceModal = Doctible.Views.Support.Modal.extend({
    additionalEvents: {
      'click button.add': 'addService'
    },

    templateID: '#add-service-modal',

    postInitialize: function() {
      this.$('form').validate();
    },

    modalHeaderContent: function() {
      this.$('.modal-header').remove();
    },

    modalBodyContent: function() {
      return _.template($(this.templateID).text())();
    },

    onShown: function() {
      var collection = this.collection;
      new (Doctible.Views.SelectInput.extend({
        el: this.$('select'),

        appendOptions: function(model) {
          // Only append if provider hasn't yet added medical service
          var services = collection.where({ medical_service_id: model.get('id') });
          if (services.length > 0) { return; }
          this.$el.append(this.optionFromModel(model));
        },

        optionFromModel: function(model) {
          return $('<option>', { text: model.get('description'), val: model.get('id') });
        }
      }))
    },

    onHidden: function() {
      this.remove();
    },

    addService: function() {
      if (this.valid()) {
        var hide = _.bind(this.hide, this);
        this.collection.create(this.formData(), { success: hide });
      }
    },

    valid: function() {
      // TODO: Get form validation to work on select input
      return this.$('form').valid() && this.$('select').val();
    },

    formData: function() {
      return {
        medical_service_id: this.$('select').val(),
        name: this.$('[name="name"]').val(),
        cash_price: this.$('[name="cash_price"]').val(),
        quick_note: this.$('[name="quick_note"]').val()
      }
    }
  });
});
