import { namespace } from 'src/namespace';
import './requested_appointments/booked_appointments.js'
import './requested_appointments/confirmed_appointments.js'

export default function() {};
namespace('Doctible.Views.ProviderPortal', function (exports) {
  exports.AppointmentPage = Backbone.View.extend({
    el: '.provider_requested_appointments.index',
    events: {
      'click .close-notice': 'closeNotice'
    },

    initialize: function () {
      this.collection = new Doctible.Collections.RequestedAppointments;
      this._listenForCheckInSuccessEvent();
      this.collection.fetch({ success: _.bind(this.render, this) });
    },

    render: function () {
      this.booked = this.collection.where({status: "booked"});
      this.confirmed = this.collection.where({status: "confirmed"});
      this.bookedCollection =
        new Doctible.Collections.BookedAppointments(this.booked);
      _.each(this.bookedCollection.models, _.bind(this.listenForChange, this));
      this.confirmedCollection =
        new Doctible.Collections.ConfirmedAppointments(this.confirmed);

      this.bookedAppointments = new Doctible.Views.ProviderPortal.RequestedAppointments.BookedAppointments({collection: this.bookedCollection});
      this.confirmedAppointments = new Doctible.Views.ProviderPortal.RequestedAppointments.ConfirmedAppointments({collection: this.confirmedCollection});
    },

    listenForChange: function(model) {
      this.listenTo(model, 'change:status', this.moveAppointment);
    },

    moveAppointment: function(model) {
      if (model.get('status') === 'confirmed') {
        this.bookedCollection.remove([model]);
        this.confirmedCollection.add([model]);
        this.showNotice("Patient " + model.get('name') + " confirmed.")
      }
    },

    showNotice: function(message) {
      var noticeEl = this.$('p.notice');
      noticeEl.hide();
      noticeEl.find('.notice-text').text(message);
      noticeEl.fadeIn(1000);
    },

    _listenForCheckInSuccessEvent: function(){
      this.listenTo(Backbone, 'patient-checked-in', function (model) {
        this.confirmedCollection.remove([model]);
        this.collection.remove([model]);
      }, this );
    },

    closeNotice:function(){
      this.$('p.notice').hide();
    }
  });

});
