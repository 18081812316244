import { namespace } from 'src/namespace';
import moment from 'moment';

export default function() {};
namespace('Doctible.Views.Search', function (exports) {
  exports.Results = Backbone.View.extend({
    el: '.results',

    initialize: function () {
      this.collection.on('request', this.blur, this)
      this.collection.on('sync', this.render, this);
      this.providers = [];
    },

    render: function () {
      this.$el.text('');
      _.invoke(this.providers, 'remove');
      this.eventsCollection = new Doctible.Collections.AvailableEvents;
      this.providers = this.collection.map(this._appendModel, this);
      this.fetchEvents();
      this.unblur();
    },

    fetchEvents: function() {
      this.eventsCollection.fetch({ data: {
        provider_location_ids: this.collection.pluck('id'),
        start: moment().add(1, 'days').format('YYYY-MM-DD'),
        end: moment().add(7, 'days').format('YYYY-MM-DD')
      }})
    },

    blur: function() {
      this.$el.addClass('blurred');
    },

    unblur: function() {
      this.$el.removeClass('blurred');
    },

    _appendModel: function (model) {
      var klass;
      if (model.get('services')) {
        klass = exports.ProviderWithServices
      } else {
        klass = exports.ProviderSpecialtyOnly
      }
      model.insurance_param = this.collection.params.insurance
      var provider = new klass({ model: model, collection: this.eventsCollection });
      this.$el.append(provider.$el);
      if (this.collection.params.insurance == 'cash') {
        provider.showPrice();
      }
      return provider;
    }
  });
});
