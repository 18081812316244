import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Support', function (exports) {
  exports.Form = Backbone.View.extend({
    tagName: 'form',
    attributes: {
      method: 'post'
    },
    validation: {},

    initialize: function() {
      this.render();
      this.setupValidation();
    },

    render: function() {},

    // Parses the form returning a js object with field names as keys
    formData: function() {
      return _.reduce(this.$el.serializeArray(), function(data, field) {
        data[field.name] = field.value;
        return data;
      }, {});
    },

    setupValidation: function() {
      var submitForm = _.bind(this.submitForm, this);
      this.$el.validate(_.extend(this.validation, { submitHandler: submitForm }));
    },

    submit: function() {
      this.$el.submit();
    }
  });
});
