import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal', function(exports) {
  exports.RemoveContactModal = Doctible.Views.Support.TemplateModal.extend({
    templateID: '#remove-contact-modal',

    additionalEvents: {
      'click button.remove': 'removeContact'
    },

    postRender: function() {
      this.$('.name').text(this.model.get('name') || this.model.get('contact'));
    },

    removeContact: function() {
      this.model.destroy();
      this.hide();
    }
  });
});
