import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal', function(exports) {
  exports.AppointmentModal = Doctible.Views.Support.Modal.extend({
    postRender: function() {
      this.model = this.model || new Doctible.Models.NewAppointment
      this.listenTo(this.model, 'sync', this.hide);
      this.form = new exports.AppointmentForm({ model: this.model });
      this.$el.addClass("appointment-modal");
    },

    modalHeaderContent: function() {
      return '<h3>Create an appointment</h3>';
    },

    modalBodyContent: function() {
      return this.form.$el;
    },

    onShown: function() {
      this.form.setupSelect();
    },

    onHide: function() {
      this.form.removeDatepickers();
    },

    onHidden: function() {
      this.remove();
    }
  });
});
