import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Collections', function(exports) {
  exports.ProviderMedicalServices = Backbone.Collection.extend({
    model: Doctible.Models.ProviderMedicalService,
    url: '/api/provider_medical_services',

    save: function(options) {
      var _this = this;
      Backbone.sync('update', this, {
        url: this.url,
        contentType: 'application/json',
        data: JSON.stringify({ services: this.toJSON() }),
        success: function(models) {
          if (options && options.success) {
            options.success()
          };
        }
      })
    }
  });
});
