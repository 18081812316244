import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.RequestedAppointments', function (exports) {
  exports.AppointmentModal = Backbone.View.extend({
    events: {
      'click .cancel': 'hide',
      'hidden.bs.modal': 'remove'
    },

    initialize: function(){
      this.setElement(this.renderedTemplate());
      this.render();
    },

    render: function () {
      this.$el.modal('show');
    },

    template: function () {
      return _.template($('#appointment-modal-template').text());
    },

    renderedTemplate: function () {
      return this.template()(this.model.asHash());
    },

    hide: function (event) {
      this.$el.modal('hide');
    }
  });
});
