import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Models', function (exports) {
  exports.Service = Backbone.Model.extend({
    asHash: function () {
      return this.toJSON();
    },

    cashPrice: function () {
      // We prefer that the service has the display price attribute from the
      // service presenter
      return this.get('display_price') || this.rangedPrice() ||
             '$' + this.get('display_cash_price');
    },

    rangedPrice: function () {
      if (this.get('ranged_price')) {
        return '$' + this.get('cash_price_lower_bound') + ' ~ $' +
               this.get('cash_price_upper_bound');
      } else {
        return null;
      }
    }
  });
});
