import { namespace } from 'src/namespace';
import moment from 'moment';

export default function() {};
namespace('Doctible.Views.Shared', function (exports) {
  exports.PreferredTimesSelector = Backbone.View.extend({
    el: '#preferred-times-selection',

    events: {
      'click button.next': 'goToCheckout',
      'click button.back': 'goToCalendar'
    },

    initialize: function () {
      if (this.model) {
        this._setDatePicker();
      }
      this.$el.show();
    },

    _setDatePicker: function() {
      var next_available_request_date = this.model.get('next_available_request_date');
      var selectedDate = this.model.get('selectedDate');

      var nextAvailableDay = next_available_request_date || this.$('#datetimepicker').data('date');
      var startDate = selectedDate || nextAvailableDay;

      if (startDate){
        this.$('#datetimepicker').data('date', startDate)
      }


      $('#datetimepicker').datepicker({
        startDate: nextAvailableDay,
        format: 'yyyy/mm/dd',
        daysOfWeekDisabled: this.model.get('closed_days'),
        maxViewMode: 0,
        weekStart: 1,
      }).show(function() {
        var currentDate = $('#datetimepicker').datepicker('getDate');
        $('.selected-date').text(moment(currentDate).format("MMM D, YYYY"));
      });

      $('#datetimepicker').on('changeDate', function(ev){
        if ($("#datetimepicker").datepicker("getDate") != 'Invalid Date'){
          $('.selected-date').text(moment(ev.date).format("MMM D, YYYY"));
          $('.range-selection').show();
        }
        else {
          $('.range-selection').hide();
        }
      });
    },

    hideBackButton: function () {
      this.$('button.back').hide();
    },

    show: function () {
      this._showingCheckout = false;
      this.$el.show();
    },

    goToCalendar: function () {
      this.$el.hide();
      this.trigger('show-calendar');
    },

    goToCheckout: function () {
      if (this.preferredTimes().length === 0) {
        $('.error').text("Please select at least one preferred time");
        return false;
      }
      $('.error').text("");

      this.requestCheckout();
    },

    formData: function () {
      return {
        "service_id": $('#service_to_book').val(),
        "preferred_times": this.preferredTimes(),
        "date": this.$('#datetimepicker').datepicker('getDate'),
        "payment": $("#payment").val()
      };
    },

    preferredTimes: function () {
      var times = _.map(this.$('input[name="preferred_time"]:checked'), function (el) {
        return $(el).val();
      });
      return _.without(times, '');
    },

    selectedDate: function(){
      return this.$('#datetimepicker').datepicker('getDate');
    }
  });
});
