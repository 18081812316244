import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views', function (exports) {
  exports.Calendar = Backbone.View.extend({
    el: '.appointment-calendar-view',

    defaults: {
      views: {
        weekThreeDay: {
          type: 'basicWeek',
          duration: { days: 3 }
        }
      },
      header: {
        left: 'prev',
        center: 'title',
        right: 'next'
      },
      titleFormat: 'MMM YYYY',
      height: 300,
      firstDay: 1,
      timeFormat: 'h:mm a'
    },

    eventClick: function(event) {
      if (!event.closed) {
        var args = {
          'provider_location_id': this.model.get('id'),
          'ei': event.id,
          'es': event.start.format("YYYY-MM-DD"),
          'treatment': this.model.get("selected_service_id")
        };
        this.trigger('event-selected', args)
      }
    },

    viewRender: function (view, element) {
      $(".fc-prev-button").attr("disabled",
        (view.start.format() <= this.firstAvailableTime));
    },

    eventMouseover: function( event, jsEvent, view ) {
      $(this).css( { color:'#337A94', backgroundColor:"#c1d7de", borderColor: "#c1d7de" } );
    },

    eventMouseout: function(calEvent, jsEvent, view) {
      $(this).css( { color:'#ffffff', backgroundColor:"#03949d" } );
    },

    show: function (firstAvailableTime) {
      this.firstAvailableTime = firstAvailableTime;
      var options = {
        defaultDate: firstAvailableTime,
        eventSources: [{
          url: '/api/provider_scheduled_events/available_events/?provider_location_id=' + this.model.get('id'),
          cache: true
        }],
        eventClick: _.bind(this.eventClick, this),
        viewRender: _.bind(this.viewRender, this),
        eventMouseover: this.eventMouseover,
        eventMouseout: this.eventMouseout,
      };

      options.defaultView = this.desiredView();
      options = _.extend(this.defaults, options);

      this.$el.fullCalendar(options);
    },

    desiredView: function() {
      if (this.$el.width() < 520) {
        return 'weekThreeDay';
      } else {
        return 'basicWeek';
      }
    }
  });
});
