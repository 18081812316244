import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.AppointmentWidget', function(exports) {
  exports.Show = Backbone.View.extend({
    el: '#appointment-widget',
    events: { },

    initialize: function() {
      new exports.ServicesSelect({
        el: this.$('select[name=service]'),
        collection: this.model.services()
      });
      new exports.PaymentMethodSelect({
        el: this.$('select[name=payment_method]'),
        collection: this.model.paymentMethods()
      });
      this.calendar =
        new exports.Calendar({ el: '#calendar-widget', model: this.model });
      this.listenTo(this.calendar, 'eventSelected', this.submitForm)
    },

    pin: function(containerBottom) {
      this.$el.removeClass('bottom');
      var bottom = this.$el.outerHeight() + this.$el.offset().top
      var outsideContainer = bottom >= containerBottom;
      $('.side-menu-col').toggleClass('hide', outsideContainer);
      this.$el.toggleClass('bottom', outsideContainer)
    },

    submitForm: function(eventData) {
      Doctible.Support.startCheckoutSession(_.extend(eventData, {
        provider_location_id: this.model.get('id'),
        treatment: this.$('[name=service]').val(),
        payment_method: this.$('[name=payment_method]').val(),
        insurance: this.$('[name=payment_method]').val()
      }));
    }
  });
});
