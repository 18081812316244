import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Checkout', function (exports) {
  exports.Promo = Backbone.View.extend({
    initialize: function () {
      this.promoPrice = new Doctible.Models.PromoPrice();
      this.promoPrice.on('sync', this.render, this);
    },

    events: {
      'click .btn-promo': 'getPromoPrice'
    },

    render: function () {
      if (this.promoPrice.get("ok") == true){
        this._showPromoCodeSuccess(this.promoPrice);
      }else{
        this._showPromoCodeError();
      }
    },

    _showPromoCodeSuccess: function(promoPrice){
      this.$('.status').show();
      this.$('.status').removeClass("error").addClass("success")
        .text(promoPrice.get("message") || "Promotional Code has been applied!")
        .show();
    },

    _showPromoCodeError: function(){
      this.$('.status').removeClass("success").addClass("error")
        .text("Invalid promotional code.").show();
    },

    getPromoPrice: function(){
      this.promoPrice.fetch({
        data: {
          provider_id: this.model.get('provider_id'),
          promo_code: this.$('input[name=promo]').val(),
          service_id: this.model.get('service_id')
        }
      });
    }
  });
});
