import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Collections', function (exports) {
  exports.AvailableEvents = Backbone.Collection.extend({
    model: Doctible.Models.AvailableEvent,
    url: '/api/provider_scheduled_events/available_events',

    groupedByDate: function() {
      return this.groupBy(function(model) {
        return model.date();
      });
    }
  });
});
