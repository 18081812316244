import RequestedAppointments from './requested_appointments.js';
import AvailableEvents from './available_events.js';
import AvailablePhoneNumbers from './available_phone_numbers.js';
import BackgroundAttributes from './background_attributes.js';
import BookedAppointments from './booked_appointments.js';
import ConfirmedAppointments from './confirmed_appointments.js';
import CustomProviderMedicalServices from './custom_provider_medical_services.js';
import MedicalServiceLabels from './medical_service_labels.js';
import OfficeHours from './office_hours.js';
import PastAppointments from './past_appointments.js';
import ProviderMedicalServices from './provider_medical_services.js';
import providers from './providers';
import Search from './search';
import Services from './services.js';
import Specialties from './specialties.js';
import SpecialtyInsurances from './specialty_insurances.js';

export default {
  RequestedAppointments,
  AvailableEvents,
  AvailablePhoneNumbers,
  BackgroundAttributes,
  BookedAppointments,
  ConfirmedAppointments,
  CustomProviderMedicalServices,
  MedicalServiceLabels,
  OfficeHours,
  PastAppointments,
  ProviderMedicalServices,
  providers,
  Search,
  Services,
  Specialties,
  SpecialtyInsurances,
}
