import '../support/template_modal.js';
import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal', function(exports) {
  exports.AddPatientsModal = Doctible.Views.Support.TemplateModal.extend({
    templateID: '#add-patients-modal',

    additionalEvents: {
      'click button.add-to-list': 'addPatients',
      'click button.add-row': 'addRow',
    },

    postRender: function() {
      this.setUpRows();
    },

    setUpRows: function() {
      this.rows = this.$('tr.patient-row').map(_.bind(this.setUpPatientRow, this));
    },

    setUpPatientRow: function(index, row) {
      var newRow = new exports.PatientRow({ el: row })
      newRow.setRowNumber(index + 1);
      newRow.$('.remove').on('click', _.bind(function() {
        newRow.remove();
        this.setUpRows();
      }, this));
      return newRow;
    },

    addRow: function() {
      var clonedRow = this.$('tr.patient-row').last().clone()
      clonedRow.find('input').val('')
      this.$('tbody').append(clonedRow).animate({ scrollTop: this.$('tbody')[0].scrollHeight }, 200);
      // Reset row views so we don't need to worry about numbering
      this.setUpRows();
    },

    addPatients: function() {
      _.map(this.rows, _.bind(this.addPatient, this));
      this.hide();
    },

    addPatient: function(row) {
      var rowEl = row.$el
      var name = rowEl.find('[name="name"]').val();
      var contact = rowEl.find('[name="contact"]').val();
      var contact_by = rowEl.find('select').val();
      this.create({
        name: name,
        contact: contact,
        contact_by: contact_by
      });
    },

    create: function(patient) {
      this.collection.createNewContact(patient);
    }
  });
});
