import { namespace } from 'src/namespace';
import 'backbone.paginator'

export default function() {};
namespace('Doctible.Collections.Search', function (exports) {
  exports.Treatments = Backbone.PageableCollection.extend({
    model: Doctible.Models.Search.Treatment,
    url: function(){
      var params = $.param(this.params);
      return '/api/content_search/treatments?' + params;
    },

    state: {
      firstPage: 1,
      pageSize: 10,
      currentPage: 1,
    },

    queryParams: {
      currentPage: "current_page",
      pageSize: "page_size"
    },

    parseState: function (resp, queryParams, state, options) {
      this.state.totalRecords = resp[0]["total_entries"];
      this.state.totalPages = Math.ceil(this.state.totalRecords / this.state.pageSize);
      this.state.currentPage = state.currentPage;
      return {totalRecords: resp.total_count};
    },

    parseRecords: function (resp) {
      this.state.totalRecords = resp[0]["total_entries"];
      this.state.totalPages = Math.ceil(this.state.totalRecords / this.state.pageSize);
      return resp[1];
    },

    parseLinks: function (resp, xhr) {
      return resp;
    },

    averageLat: function () {
      return this.averageProp('lat');
    },

    averageLng: function () {
      return this.averageProp('lng');
    },

    averageProp: function (prop) {
      var sum = function (memo, model) {
        return memo + new Number(model.get(prop))
      }
      propSum = this.reduce(sum, 0, this)
      return propSum / this.length
    },

    updateInsurance: function(insurance) {
      this.params.insurance = insurance;
      this.resetAndFetch();
    },

    setParam: function(name, value) {
      if (value) {
        this.params[name] = value;
      } else {
        delete this.params[name];
      }
    },

    updateFilter: function(name, value) {
      this.setParam(name, value);
      this.resetAndFetch();
    },

    updateAddressBarLocation: function() {
      var state = this.currentState();
      var address = location.pathname + '?' + $.param(state);
      Doctible.Support.History.replaceState(state, null, address);
    },

    updateFilterWithoutReset: function(name, value) {
      this.setParam(name, value);
      // TODO: Determine a better way to handle this so we avoid removing
      // necessary parameters (e.g. gclid for adwords)
      // this.updateAddressBarLocation();
    },

    resetAndFetch: function() {
      this.state.currentPage = 1;
      this.updateAddressBarLocation();
      this.fetch();
    },

    currentState: function () {
      return _.extend({
        si: this.params.specialty_id,
        sei: this.params.search_item,
        type: 'treatments',
        current_page: this.state.currentPage
      }, _.omit(this.params, ['specialty_id', 'search_item']));
    },
  })
});
