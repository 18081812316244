import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Support', function (exports) {
  exports.Modal = Backbone.View.extend({
    className: 'modal fade',
    attributes: {
      'tabindex': '-1',
      'role': 'dialog',
      'aria-hidden': true
    },

    additionalEvents: {},

    events: function() {
      return _.extend({
        'shown.bs.modal': 'onShown',
        'hidden.bs.modal': 'onHidden',
        'hide.bs.modal': 'onHide'
      }, this.additionalEvents)
    },

    initialize: function () {
      this.render();
      this.addModalContent();
      this._addModalHeaderContent();
      this._addModalBodyContent();
      this._addModalFooterContent();
      this.postInitialize();
    },

    postInitialize: function() {},

    render: function () {
      $('body').append(this.$el);
      // TODO: Think about adding a better template system and using it here
      this.$el.append(
        '<div class="modal-dialog">' +
          '<div class="modal-content">' +
            '<div class="modal-header">' +
              '<button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
                '<i class="fa fa-close"></i>' +
              '</button>' +
            '</div>' +
            '<div class="modal-body"></div>' +
            '<div class="modal-footer"></div>' +
          '</div>' +
        '</div>'
      );
      this.postRender();
    },

    postRender: function() {},

    // Prefer the other modal content methods for adding content as this method
    // requires the subclass to have too much knowledge of the html structure
    // of the modal
    addModalContent: function() {},

    // Override these methods in subclass to set modal content
    modalHeaderContent: function() {},
    modalBodyContent: function() {},
    modalFooterContent: function() {
      this.$('.modal-footer').remove();
    },

    _addModalHeaderContent: function () {
      this.$('.modal-header').append(this.modalHeaderContent());
    },

    _addModalBodyContent: function () {
      this.$('.modal-body').append(this.modalBodyContent());
    },

    _addModalFooterContent: function () {
      this.$('.modal-footer').append(this.modalFooterContent());
    },

    show: function () {
      this.$el.modal('show')
    },

    hide: function () {
      this.$el.modal('hide')
    },

    onShown: function () {},

    onHidden: function () {},

    onHide: function() {}
  })
});
