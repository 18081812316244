import mapPointHoverUrl from './images/map-point-hover.svg'
import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Shared', function (exports) {
  exports.Map = Backbone.View.extend({
    el: '.map',

    initialize: function () {
      this.render();
    },

    render: function () {
      var mapOptions = {
        center: new google.maps.LatLng(this.model.get('lat'), this.model.get('lng')),
        zoom: 13,
        mapTypeControl: false,
        panControl: false,
        zoomControl: true,
        scrollwheel: false,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL
        },
        scaleControl: false,
        streetViewControl: false
      };

      var styleArray = [{ "featureType": "water", "stylers": [{ "saturation": 43 }, { "lightness": -11 }, { "hue": "#0088ff" }] }, { "featureType": "road", "elementType": "geometry.fill", "stylers": [{ "hue": "#ff0000" }, { "saturation": -100 }, { "lightness": 99 }] }, { "featureType": "road", "elementType": "geometry.stroke", "stylers": [{ "color": "#808080" }, { "lightness": 54 }] }, { "featureType": "landscape.man_made", "elementType": "geometry.fill", "stylers": [{ "color": "#ece2d9" }] }, { "featureType": "poi.park", "elementType": "geometry.fill", "stylers": [{ "color": "#ccdca1" }] }, { "featureType": "road", "elementType": "labels.text.fill", "stylers": [{ "color": "#767676" }] }, { "featureType": "road", "elementType": "labels.text.stroke", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "poi", "stylers": [{ "visibility": "off" }] }, { "featureType": "landscape.natural", "elementType": "geometry.fill", "stylers": [{ "visibility": "on" }, { "color": "#b8cb93" }] }, { "featureType": "poi.park", "stylers": [{ "visibility": "on" }] }, { "featureType": "poi.sports_complex", "stylers": [{ "visibility": "on" }] }, { "featureType": "poi.medical", "stylers": [{ "visibility": "on" }] }, { "featureType": "poi.business", "stylers": [{ "visibility": "simplified" }] }];

      var map = new google.maps.Map(this.$el[0], mapOptions);
      map.setOptions({ styles: styleArray });

      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(this.model.get('lat'), this.model.get('lng')),
        icon: mapPointHoverUrl,
        map: map,
      });

      if (this.model.get('street_address') && this.model.get('city_state_zip')) {
        var contentString = $('<div>', { 'class': 'info-window' }).append(
          $('<div>', { text: this.model.get('street_address'), 'class': 'address' })
        ).append(
          $('<div>', { text: this.model.get('city_state_zip'), 'class': 'city-state' })
        );
        var infowindow = new google.maps.InfoWindow({
          content: contentString[0]
        });
        infowindow.open(map, marker);
        map.panBy(0, -50);
      }
      marker.setMap(map);
    },
  });
});
