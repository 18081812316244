import './select_input'
import '../collections/specialty_insurances'
import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views', function (exports) {
  exports.InsurancesSelect = exports.SelectInput.extend({
    collection_class: Doctible.Collections.SpecialtyInsurances,
    css_class: 'insurance-select',

    update: function (specialty_id) {
      if ( this.$el.data('specialty_id') === specialty_id ) { return };
      this.collection.fetch({ data: { specialty_id: specialty_id } });
      this.$el.data('specialty_id', specialty_id);
      this.$el.prop('disabled', false);
    },

    reset: function () {
      this.$('option').remove();
      var cashOption =
        $('<option>', { value: 'cash', text: "I'm paying for myself" })
      this.$el.prepend(cashOption);
    },

    appendOptions: function (model) {
      this.$('.insurances').append(this.optionFromModel(model))
    },
  });
});
