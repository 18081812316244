import { namespace } from 'src/namespace';
import './event.js'

export default function() {};
namespace('Doctible.Views.ProviderPortal.Schedules', function(exports) {
  exports.AvailableEvent = exports.Event.extend({
    titleContent: function() {
      return this.title() + '<span class="popovericon delete-event" title="Delete"><i class="fa fa-trash-o pop"></i></span>';
    }
  });
});
