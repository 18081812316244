import { namespace } from 'src/namespace';
import './event_modal.js'

export default function() {};
namespace('Doctible.Views.ProviderPortal.Schedules', function(exports) {
  exports.DeleteEventModal = exports.EventModal.extend({
    templateID: '#delete-event-modal',

    template: function() {
      return Doctible.Views.Support.ModelTemplate.extend({
        templateID: this.templateID,
        events: {
          'click .delete-all': 'deleteAll',
          'click .delete-one': 'deleteOne'
        },

        postRender: function() {
          var repeating = this.model.extendedProps.repeating !== 0;
          if (repeating) {
            this.$('.non-recurring-event-modal').remove()
          } else {
            this.$('.recurring-event-modal').remove()
          }
        },

        deleteOne: function() {
          Doctible.Support.destroyProviderScheduledEvents({ id: this.model.id, all_occurrence: false }, this);
        },

        deleteAll: function() {
          Doctible.Support.destroyProviderScheduledEvents({ id: this.model.id, all_occurrence: true }, this);
        },

        hideModal: function() {
          this.$el.modal("hide");
        }
      });
    }
  })
});
