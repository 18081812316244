import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views', function (exports) {
  exports.SelectInput = Backbone.View.extend({
    initialize: function () {
      var klass = this.collection_class || Backbone.Collection;
      this.collection = new klass(this.$el.data('collection'));
      this.collection.on('sync', this.render, this);
      // Re-render select2 so the width adjusts with the responsive design
      // TODO: a bette way to handle this? Perhaps if page shrinks under certain size then...?
      $(window).on('resize', _.bind(this.render, this));
      this.render();
      this.postInitialize();
    },

    render: function () {
      var setValue = this.$el.val() || this.$el.data('default');
      this.reset();
      _.each(this.collection.models, this.appendOptions, this);
      this.$el.val(setValue).change();
    },

    reset: function () {
      this.$('option').remove();
    },

    appendOptions: function(model) {
      this.$el.append(this.optionFromModel(model));
    },

    optionFromModel: function (model) {
      return $('<option>', { text: model.get('name'), val: model.get('id') });
    },

    value: function () {
      return this.$el.val();
    },

    // Stub hook method to be used in subclassed views if necessary
    postInitialize: function () {}
  });
});
