import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal', function(exports) {
  exports.EditTable = Backbone.View.extend({
    el: '.patients.table',
    contactBy: null,
    rowClass: exports.EditRow,

    initialize: function() {
      this.rows = [];
      this.listenTo(this.collection, 'add', this.addPatient);
      this.postInitialize();
    },

    postInitialize: function() {},

    addPatient: function(patient) {
      this.addRow(patient);
    },

    addRow: function(patient) {
      var row = new this.rowClass({ model: patient });
      this.listenTo(patient, 'error', _.bind(this.moveToTop, this, row));
      this.listenTo(patient, 'change:contact_count', _.bind(this.addRowToBody, this, row));
      this.addRowToBody(row);
    },

    moveToTop: function(row) {
      this.$('tbody').prepend(row.$el);
    }
  });
});
