import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Models', function(exports) {
  exports.NewAppointment = Backbone.Model.extend({
    url: '/api/providers/appointment_requests',

    initialize: function() {
      this.on('sync', function() { Backbone.trigger('event-modified') });
    }
  });
});
