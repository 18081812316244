import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal', function(exports) {
  exports.PatientRow = Backbone.View.extend({
    events: {
      'keyup [name=contact]': 'checkContactInfo',
      'focusout [name=contact]': 'checkContactInfo',
      'click .remove': 'remove'
    },

    setRowNumber: function(num) {
      this.$('td').first().text(num + '.');
    },

    checkContactInfo: function() {
      var contactVal = this.$('[name="contact"]').val().trim();
      if (Doctible.Support.String.isEmail(contactVal)) {
        this.checkRecentlySent({ email: contactVal });
        this.$('[name="contact_by"]').val('email');
      } else if (Doctible.Support.String.isPhone(contactVal)) {
        this.checkRecentlySent({ phone_number: contactVal });
        if($("select[name='contact_by'] option[value='sms']").length > 0){
          this.$('[name="contact_by"]').val('sms');
        }else{
          this.$('[name="contact_by"]').val('text');
        }
      }
    },

    checkRecentlySent: function(data) {
      var request = new Doctible.Models.Providers.ReviewRequest(data);
      request.checkRecent(_.bind(this.showRecentlySentResponse, this));
    },

    showRecentlySentResponse: function(data) {
      if (data.warning) {
        this.$('.text-warning').length > 0 ||
          this.$('td:nth-child(3)').append($('<p>', { class: 'text-warning' }));
        this.$('.text-warning').text(data.warning);
      } else {
        this.$('.text-warning').remove();
      }
    }
  });
});
