import { namespace } from 'src/namespace';
import './attribute_form'
import './attribute_item'

export default function() {};
namespace('Doctible.Views.ProviderPortal.Background', function (exports) {
  exports.Attributes = Backbone.View.extend({

    initialize: function () {
      this.collection.on('add', this._appendModel, this);
      this.attributes = this.collection.map(this._appendModel, this);
      this.render();
    },

    render: function() {
      new Doctible.Views.ProviderPortal.Background.AttributeForm({collection: this.collection, el: this.$('form') });
    },

    _appendModel: function (model) {
      var attribute = new Doctible.Views.ProviderPortal.Background.AttributeItem({ model: model });
      this.$('ul').append(attribute.$el);
    },
  });
});
