import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.Schedules', function(exports) {
  exports.EventModal = Doctible.Views.Support.Modal.extend({
    templateID: '#appointment-modal',

    postRender: function() {
      this.$('.modal-header').remove();
      // TODO: Move into separate file, maybe?
      var template = this.template();
      this.renderedTemplate = new template({model: this.model})
    },

    modalBodyContent: function() {
      return this.renderedTemplate.$el;
    },

    template: function() {
      return Doctible.Views.Support.ModelTemplate.extend({
        templateID: this.templateID,
        postRender: function() {
          // Shows the buttons that correspond with the status of the appointment
          var appointment = this.model.get('appointment');
          if (!appointment) { return; };
          this.$('.' + appointment.status).show();
        }
      });
    },

    postInitialize: function() {
      this.$('button.edit').click(_.bind(this.editEvent, this));
      this.$('button.confirm').click(_.bind(this.confirmEvent, this));
      this.$('button.checkin').click(_.bind(this.checkInEvent, this));
    },

    editEvent: function(event) {},

    confirmEvent: function() {
      this.appointment = new Doctible.Models.Appointment({ id: this.model.get('id') });
      this.appointment.save({ status: 'confirmed' }, {
        wait: true,
        success: _.bind(function() {
          Backbone.trigger('event-modified');
          this.hide();
        }, this)
      });
    },

    checkInEvent: function() {
      this._callFetch(new Doctible.Models.CheckInPatient);
    },

    // Makes a get request to confirm or checkin patient depending on which
    // model is used
    _callFetch: function(patient) {
      patient.fetch({
        data: { appointment_id: this.model.get('id') },
        success: _.bind(function() {
          Backbone.trigger('event-modified');
          this.hide();
        }, this)
      });
    }
  });
});
