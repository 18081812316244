import { namespace } from 'src/namespace';
import moment from 'moment';

export default function() {};
namespace('Doctible.Views.Provider', function(exports) {
  exports.AppointmentWidget = Backbone.View.extend({
    events: {
      'click button[type=submit]': 'goToCheckout',
      'change [type=radio], [type=checkbox]': 'toggleButtonState'
    },

    initialize: function() {
      this.render();
    },

    render: function() {
      this.collection = new Doctible.Collections.AvailableEvents();
      this.collection.on('sync', this.updateEvents, this);
      this.setPaymentOption();
      this.setService();
      this.setPopover();

      var next_available_request_date = this.model.get('next_available_request_date');
      var startDate = next_available_request_date;

      if (startDate){
        this.$('#datetimepicker').data('date', startDate)
      }

      this.$('#datetimepicker').datepicker({
        startDate: next_available_request_date,
        format: 'mm-dd-yyyy',
        daysOfWeekDisabled: this.model.get('closed_days'),
        maxViewMode: 0,
        weekStart: 0,
      }).on('changeDate', _.bind(this.checkEvents, this))
      $('.active').trigger('click');
    },


    setPopover: function(){
      this.$('[data-toggle=popover]').popover();
    },

    setPaymentOption: function(){
      if (this.model.get('insurance'))
        $('[name=payment_method] option[value='+ this.model.get('insurance') + ']').prop('selected', true);
    },

    setService: function(){
      if(this.model.get('sei'))
        $('[name=service] option[value='+ this.model.get('sei') + ']').prop('selected', true);
    },

    pin: function(containerBottom) {
      this.$el.removeClass('bottom');
      var bottom = this.$el.outerHeight() + this.$el.offset().top
      var outsideContainer = bottom >= containerBottom;
      $('.side-menu-col').toggleClass('hide', outsideContainer);
      this.$el.toggleClass('bottom', outsideContainer)
    },

    checkEvents: function(date) {
      this.currentDate = date || this.$('#datetimepicker').datepicker('getDate');
      this.collection.fetch({ data: {
        provider_location_id: this.model.get('id'),
        start: this.currentDate.format('yyyy-mm-dd'),
        end: this.currentDate.format('yyyy-mm-dd')
      }})
    },

    updateEvents: function() {
      this.resetTimes();
      if (this.collection.length > 0) {
        this.showAvailableTimes();
      } else {
        this.$('.preferred-times').show();
        this.$('[type=submit]').text('Request Appointment Now');
      }
    },

    resetTimes: function() {
      this.currentDate = this.currentDate || $('#datetimepicker').datepicker('getDate');
      this.$('.available-times, .preferred-times').hide().find('.date').
        text(this.currentDate.format('MM d'));
      this.$('.available-times .times').text('');
      this.$('input:checked').attr('checked', false);
      this.toggleButtonState();
    },

    showAvailableTimes: function() {
      this.collection.each(function(model) {
        var input = $('<input>', {
          type: 'radio',
          value: model.id,
          name: 'event'
        });
        var label = $('<label>', { html: input }).append(model.formattedStart());
        this.$('.available-times .times').append(label)
      });
      this.$('.available-times').show();
      this.$('[type=submit]').text('Book Appointment Now');
    },

    goToCheckout: function(event) {
      event.preventDefault();
      var eventData;
      if (this.$('.available-times').is(':visible')) {
        eventData = { ei: this.$('[type=radio]:checked').val() };
      } else {
        eventData = {
          preferred_times: this.preferredTimes(),
          // Datepicker, unforunately, does not support the date formatting
          // that we use when passing this to the server. We may want to look
          // into a simpler way to format without creating a new moment object.
          date: moment(this.currentDate.format('yyyy-mm-dd')).
                format('ddd MMM D YYYY')
        }
      }
      Doctible.Support.startCheckoutSession(_.extend(eventData, {
        provider_location_id: this.model.get('id'),
        treatment: this.$('[name=service]').val(),
        payment_method: this.$('[name=payment_method]').val(),
        insurance: this.$('[name=payment_method]').val()
      }));
    },

    preferredTimes: function () {
      return _.map(this.$('[name=preferred_time]:checked'), function (el) {
        return $(el).val();
      });
    },

    toggleButtonState: function() {
      this.$('[type=submit]').prop('disabled', !this.$('input').is(':checked'))
    }
  })
})
