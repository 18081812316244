import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Provider', function (exports) {
  exports.ServiceSelectModal = Backbone.View.extend({
    el: '#service-select-modal',
    events: {
      'shown.bs.modal': 'render',
      'click .cancel': 'hide',
      'click .confirm': 'goToCheckout',
      'hidden.bs.modal': 'reset'
    },

    show: function (eventData) {
      // eventData should be a hash with keys pid and ei where pid is the
      // provider id and ei is the event id. TODO: we should be able to change
      // the api to only rely on ei given that a ProviderScheduledEvent belongs
      // to a provider
      this.delegateEvents();
      this.checkoutData = _.extend(eventData, {
        provider_location_id: this.model.get('id')
      });
      this.$el.modal('show');
    },

    render: function () {
      serviceEl = this.$('.service-select')
      serviceEl.data('collection', this.model.get('services'));
      this.select = new exports.ServicesSelect({ el: serviceEl });
      this.showPrices();
      this.select.$el.on('change', _.bind(this.showPrices, this));
    },

    showPrices: function () {
      var serviceId = Number(this.select.$el.val());
      var service = this.select.collection.get(serviceId);

      if (service) {
        if (service.cashPrice() == '$undefined'){
          this.$('.row.prices').hide();
        }else{
          this.$('.cash.price').text(service.cashPrice());
        }
      }
      this.$('.confirm').prop('disabled', false)
    },

    goToCheckout: function () {
      var serviceId = Number(this.select.$el.val());
      params = _.extend(this.checkoutData, { treatment: serviceId });
      Doctible.Support.startCheckoutSession(params);
    },

    hide: function (event) {
      this.$el.modal('hide');
      // Clear selection in select
      this.select.render();
    },

    /**
     * resets modal events so there are no double bindings when opening
     * modal again
     */
    reset: function (event) {
      this.undelegateEvents();
      this.$('.price').text('');
      this.select.$el.off('change');
      this.select.undelegateEvents();
      this.$('.confirm').prop('disabled', true)
    }
  })
});
