// A logged in provider's medical services
import { namespace } from 'src/namespace';
import './provider_medical_services'

export default function() {};
namespace('Doctible.Collections', function(exports) {
  exports.CustomProviderMedicalServices = exports.ProviderMedicalServices.extend({
    url: '/api/provider_medical_services/custom',
    model: Doctible.Models.ProviderMedicalService,
  });
});
