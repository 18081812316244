import { namespace } from 'src/namespace';
import moment from 'moment';

export default function() {};
namespace('Doctible.Models', function(exports) {
  exports.AvailableEvent = Backbone.Model.extend({
    day: function() {
      return this.startAsMoment().format('D');
    },

    formattedStart: function() {
      return this.startAsMoment().format('h:mma');
    },

    date: function() {
      return this.startAsMoment().format('YYYY-MM-DD')
    },

    startAsMoment: function() {
      return moment(this.get('start'));
    }
  });
});
