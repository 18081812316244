import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.Services', function(exports) {
  exports.Row = Doctible.Views.Support.ModelTemplate.extend({
    templateID: '#service-row-template',

    events: {
      'click .delete-service': 'showConfirmModal',
      'click .edit-service': 'showEditModal'
    },

    showConfirmModal: function() {
      var modal = new (this.confirmModal())();
      modal.$('input.confirm').click(_.bind(function() {
        modal.hide();
        this.destroy();
      }, this))
      modal.show();
    },

    showEditModal: function() {
      var modal = new exports.EditServiceModal({ model: this.model });
      modal.show();
    },

    destroy: function() {
      this.model.destroy({ success: _.bind(function() {
        this.remove();
        // Bubbling up the successful destroy
        this.collection.trigger('sync');
      }, this) });
    },

    confirmModal: function() {
      return Doctible.Views.Support.Modal.extend({
        modalHeaderContent: function() {
          this.$('.modal-header').remove();
        },

        modalBodyContent: function() {
          var templateText = $('#are-you-sure-modal').text();
          return _.template(templateText)();
        },

        onHidden: function() {
          this.remove();
        }
      });
    }
  })
})
