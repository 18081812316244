import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.Services', function(exports) {
  exports.Custom = Backbone.View.extend({
    el: '.services.custom',

    events: {
      'click .add-service': 'addService'
    },

    initialize: function() {
      this.collection = new Doctible.Collections.CustomProviderMedicalServices
      new exports.Table({ collection: this.collection });
    },

    addService: function() {
      var customServiceModal = exports.AddServiceModal.extend({
        templateID: '#add-custom-service-modal',
        valid: function() {
          return this.$('form').valid();
        }
      });
      (new customServiceModal({ collection: this.collection })).show();
    }
  });
});
