import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Shared', function (exports) {
  exports.DoughnutChart = Backbone.View.extend({
    initialize: function () {
      this.render();
    },

    render: function () {
      var ctx = this.$el;
      var data = {
        labels: this.model.get('labels'),
        datasets: [
        {
          data: this.model.get('ratingData'),
          backgroundColor: this.model.get('backgroundColor'),
          hoverBackgroundColor: this.model.get('backgroundColor')
        }]
      };

      new Chart(ctx, {
        type: 'doughnut',
        data: data,
        options: {
          responsive: false,
          legend: {
            display: false
          }
        }
      });
    }
  });
});
