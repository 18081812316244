import { $, jQuery } from 'jquery';
import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Admin.Practices', function(exports) {
  exports.Setup = Backbone.View.extend({
    el: '.setup.admin_practices',

    events: {
      'submit form': 'ajaxAndReload'
    },

    ajaxAndReload: function(event) {
      event.preventDefault();
      var formEl = $(event.currentTarget);
      var requiredFields = formEl.find('input.required, select.required');
      var formComplete = _.every(requiredFields, function(input) {
        return !!$(input).val();
      });
      if (!formComplete) {
        formEl.find('.text-danger').show()
        return false;
      }
      $.ajax({
        type: formEl.attr('method'),
        url: formEl.attr('action'),
        data: formEl.serialize(),
        success: function() { location.reload(); },
        error: function(response) {
          formEl.find('.alert').show().text(response.responseText)
        }
      })
    }
  });

  () => {
    if ($('.setup.admin_practices').length > 0) new exports.Setup();
  }
});
