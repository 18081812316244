import './support/modal.js';
import { namespace } from 'src/namespace';
import moment from 'moment';

export default function() {};
namespace('Doctible.Views', function (exports) {
  exports.AppointmentAvailabilityModal = Backbone.View.extend({
    events: {
      'hidden.bs.modal': 'remove',
      'shown.bs.modal': 'showAvailability',
      'click .select-preferred-times': 'showPreferredTimes'
    },

    initialize: function () {
      var template = _.template($('#availability-modal-template').text());
      var templateValues = _.extend(this.model.asHash(), {
        service_description: 'Service Description'
      });
      this.setElement(template(templateValues));
      $('body').append(this.$el);
      this.render();
    },

    render: function () {
      this.calendarContainer().hide();
      this.noAvailabilityEl().hide();
      this._setPaymentMethodInput();
      this.$el.modal('show');
      this._setServiceDescription();
      this._hidePriceIfNull();
      this.setPopover();
    },

    setPopover: function(){
      this.$('[data-toggle=popover]').popover();
    },

    showAvailability: function () {
      this.firstAvailableTime = new Doctible.Models.FirstAvailableTime();
      this.firstAvailableTime.on('sync', this.setCalendar, this);
      this.firstAvailableTime.fetch({
        data: {
          provider_location_id: this.model.get('id'),
          service_id: this.model.get('selected_service_id')
        }
      });
    },

    _hidePriceIfNull: function() {
      if (this.model.get('display_price') == null) {
        this.$('.pay-self-option').text("I will pay for myself");
      }
    },

    _setPaymentMethodInput: function() {
      if (this.model.get('accepts_insurance')) { return; }
      this.$('[value="cash"]').prop('selected', true);
      this.$('.insurance-select').hide();
    },

    _setServiceDescription: function(){
      var services = this.model.get('services'); // for service search
      var description = this.model.get('selected_description') ||
                        this.model.get('description'); // on profile page
      description = services ? services[0].description : description;
      this.$('.service-description').html(' ' + description);
    },

    setCalendar: function () {
      var firstAvailableTime = this.firstAvailableTime.get('day');
      this.model.set({ selectedDate: this.model.get('selectedDate') });

      this.selector = new exports.PreferredTimesSelector({ model: this.model });
      this.selector.on('time-requested', this.startCheckout, this);
      if (!firstAvailableTime || this.model.get('showRequestAppt')) {
        this.model.unset('showRequestAppt');
        this.showPreferredTimes();
        this.selector.hideBackButton();
        return
      };
      this.selector.on('show-calendar', this.showCalendar, this);
      this.calendarContainer().show();
      this.calendar = new exports.Calendar({
        model: this.model,
        el: '.calendar-view'
      });
      this.calendar.show(firstAvailableTime);
      this.calendar.on('event-selected', this.startCheckout, this);
    },

    startCheckout: function(args) {
      var payment_method = this.$('[name="payment_method"]:checked').val();
      if (args.date) {
        var date = args.date;
        args.date = moment(date).format('MMM D YYYY');
      }
      var params = _.extend(args, { payment_method: payment_method });
      Doctible.Support.startCheckoutSession($.param(params));
    },

    showCalendar: function () {
      this.$('.select-preferred-times').show();
      this.noAvailabilityEl().hide();
      this.calendarContainer().show();
      this.calendar.refetchEvents();
    },

    noAvailabilityEl: function () {
      return this.$('.no-availability');
    },

    showPreferredTimes: function () {
      this.$('.select-preferred-times').hide();
      this.calendarContainer().hide();
      this.noAvailabilityEl().show();
      this.selector.show();
    },

    calendarContainer: function () {
      return this.$('.calendar');
    },

    setInsuranceOption: function() {
      this.$('.insurance-checked').prop('checked', true);
    }
  });
});
