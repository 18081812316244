import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Search', function (exports) {
  exports.ProviderWithServices = exports.Provider.extend({
    SERVICE_COUNT: 2,

    template: function () {
      return _.template($('#provider-template').text());
    },

    postRender: function () {
      this.model.set({
        insurance_param: this.model.insurance_param
      })
      this.services = new Doctible.Collections.Services(this.model.get('services'))
      this._checkInsuranceLinks();
      if (!this.model.hideRequestedAppointment()){
        this.listenTo(this.collection, 'sync', function() {
          var collection = new Doctible.Collections.AvailableEvents(
            this.collection.where({ provider_location_id: this.model.get('id') })
          );
          var availableTimes = new exports.AvailableTimes({
            model: this.model,
            collection: collection
          });
          this.$('.provider-available-times').append(availableTimes.$el);
        });
      } else {
        this.$('.or-text, .insurance, .view-profile-btn, .book-now-button').remove();
        this.$('.provider-available-times').append(
          '<div class="no-available-times">' +
              '<h5>Availablilities</h5>' +
              '<div class="no-available-container">' +
                'There is no availability for this doctor on Doctible' +
              '</div>' +
          '</div>'
        );
        this.$('.no-availability').append(
          '<p class="no-doctor">There is no availability for this doctor on Doctible</p>'
        );
      }
    },

    _checkInsuranceLinks: function() {
      if (this.model.get('accepts_insurance')) { return; }
      this.$('.or-text').hide();
      this.$('.insurance').css('visibility', 'hidden');
    },

    showPrice: function() {
      // We determine what element to show based on whether we're showing a
      // cash price or a description
      this.$(
        this.model.showsCashPrice() ? '.cash-price' : '.non-price-description'
      ).show().find('.display-price').text(this.model.displayPrice());
    }
  })
})
