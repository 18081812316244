import AddPatientsModal from './add_patients_modal.js';
import RequestedAppointments from './requested_appointments';
import AppointmentForm from './appointment_form.js';
import AppointmentModal from './appointment_modal.js';
import AppointmentPage from './appointment_page.js';
import AppointmentReminders from './appointment_reminders.js';
import BackgroundPage from './background_page.js';
import Body from './body.js';
import EditRow from './edit_row.js';
import EditTable from './edit_table.js';
import ImportModal from './import_modal.js';
import MessagePage from './message_page.js';
import OfficeHours from './office_hours';
import PastAppointmentPage from './past_appointment_page.js';
import PatientRow from './patient_row.js';
import ProgressBar from './progress_bar.js';
import ProviderLocationHours from './provider_location_hours';
import ProviderPhotoUploader from './provider_photo_uploader.js';
import RemoveContactModal from './remove_contact_modal.js';
import SchedulesPage from './schedules_page.js';
import Services from './services';

export default {
  AddPatientsModal,
  RequestedAppointments,
  AppointmentForm,
  AppointmentModal,
  AppointmentPage,
  AppointmentReminders,
  BackgroundPage,
  Body,
  EditRow,
  EditTable,
  ImportModal,
  MessagePage,
  OfficeHours,
  PastAppointmentPage,
  PatientRow,
  ProgressBar,
  ProviderLocationHours,
  ProviderPhotoUploader,
  RemoveContactModal,
  SchedulesPage,
  Services,
};
