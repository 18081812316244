import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Provider', function (exports) {
  exports.ServicesSelect = Doctible.Views.SelectInput.extend({
    css_class: 'service-select',
    selected: 'true',
    collection_class: Doctible.Collections.Services,

    appendOptions: function (model) {
      this.$el.append(
        $('<option>', {
          text: model.get('description'),
          val: model.get('id'),
          selected: this.selected
        })
      );
      // used to set the first option to be default
      this.selected = false;
    }
  });
});
