import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.RequestedAppointments', function (exports) {
  exports.ConfirmAppointmentModal = Doctible.Views.ProviderPortal.RequestedAppointments.AppointmentModal.extend({
    events: {
      'click .confirm': 'confirm',
      'click .cancel': 'hide',
      'hidden.bs.modal': 'remove'
    },

    render: function () {
      this.$el.modal('show');
      this.$('.confirm').css("display", "inline-block");

      if (this.model.get('appointment_time') === 'TBD') {
        var input = $('<input>', {
          name: 'appointment_time',
          type: 'text',
          placeholder: 'Select date and time'
        });
        this.$('.appointment-value').html(input);
        input.datetimepicker();
      }
    },

    confirm: function() {
      var timeInput = this.$('[name=appointment_time]')
      if (timeInput.length > 0) {
        var appointmentTime = timeInput.val()
        if (appointmentTime.length > 0) {
          this.model.set({ appointment_time: appointmentTime })
        } else {
          this.$('.notice').removeClass('hidden')
          return;
        }
      }
      this.model.save({ status: 'confirmed' }, {
        wait: true,
        success: _.bind(this.hide, this)
      });
    }
  });
});
