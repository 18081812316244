import { namespace } from 'src/namespace';
import './../../support/model_template.js'


export default function() {};
namespace('Doctible.Views.ProviderPortal.RequestedAppointments', function (exports) {
  exports.AppointmentRow = Doctible.Views.Support.ModelTemplate.extend({
    templateID: "#appointment_template",

    launchDialog: function(){
      new this.modalKlass({ model: this.model });
    },

    markAppointmentNoShow: function() {
      $.ajax({
        url: "/api/requested_appointments/mark_as_no_show",
        type: "PUT",
        data: { "appointment_request_id": this.model.get("id") },
        success: _.bind(this._showNotification, this, 'Appointment marked as No Show.')
      });
    },

    markAppointmentCanceled: function() {
      $.ajax({
        url: "/api/requested_appointments/mark_as_canceled",
        type: "PUT",
        data: { "appointment_request_id": this.model.get("id") },
        success: _.bind(this._showNotification, this, 'Appointment marked as Canceled.')
      });
    },

    _showNotification: function(notice) {
      $('.notice-text').text(notice);
      $('p.notice').fadeIn(1000);
      this.remove();
    }
  });
});
