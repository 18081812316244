// Use this to easily create views that render templates with models
//
// An abstract object that can be extended by an object that includes
// a templateID and a model on initialization.
import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Support', function (exports) {
  exports.ModelTemplate = Backbone.View.extend({
    initialize: function () {
      _.templateSettings.interpolate = /\{\{(.+?)\}\}/g;

      this.render();
    },

    render: function () {
      this.setElement(this.renderedTemplate());
      this.postRender();
    },

    postRender: function () {},

    template: function () {
      return _.template($(this.templateID).text());
    },

    renderedTemplate: function () {
      var data = this.model.asHash ? this.model.asHash() : this.model.toJSON();
      return this.template()(data);
    }
  });
});
