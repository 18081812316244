import { namespace } from 'src/namespace';
import './event.js'

export default function() {};
namespace('Doctible.Views.ProviderPortal.Schedules', function(exports) {
  exports.BookedEvent = exports.Event.extend({
    titleContent: function() {
      return this.title() + "<span class=\"popovericon process-patient\" title=\"Patient Check-In\"><i class=\"fa fa-map-marker\"></i></span>";
    },
  });
});
