import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Models', function(exports) {
  exports.AvailablePhoneNumber = Backbone.Model.extend({
    urlRoot: '/admin/api/available_phone_numbers',

    initialize: function() {}
  });
});
