import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Search', function (exports) {
    exports.Map = Backbone.View.extend({
      el: '.map',

      defaultMapOptions: {
        mapTypeControl: false,
        panControl: false,
        zoomControl: true,
        zoomControlOptions: {
            style: 1
        },
        scaleControl: false,
        streetViewControl: false,
        scrollwheel: false,
        zoom: 15,
        styles: [{"featureType":"water","stylers":[{"saturation":43},{"lightness":-11},{"hue":"#0088ff"}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"hue":"#ff0000"},{"saturation":-100},{"lightness":99}]},{"featureType":"road","elementType":"geometry.stroke","stylers":[{"color":"#808080"},{"lightness":54}]},{"featureType":"landscape.man_made","elementType":"geometry.fill","stylers":[{"color":"#ece2d9"}]},{"featureType":"poi.park","elementType":"geometry.fill","stylers":[{"color":"#ccdca1"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#767676"}]},{"featureType":"road","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]},{"featureType":"poi","stylers":[{"visibility":"off"}]},{"featureType":"landscape.natural","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#b8cb93"}]},{"featureType":"poi.park","stylers":[{"visibility":"on"}]},{"featureType":"poi.sports_complex","stylers":[{"visibility":"on"}]},{"featureType":"poi.medical","stylers":[{"visibility":"on"}]},{"featureType":"poi.business","stylers":[{"visibility":"simplified"}]}]
      },

      initialize: function () {
        this.collection.on('sync', this.render, this);
      },

      render: function () {
        this.markers = [];
        if ( this.collection.length === 0) {
          this.renderMapArea();
        } else {
          this.renderMapCollection();
        }
      },

      renderMapArea: function () {
        var geocoder = new google.maps.Geocoder();
        var displayMap = _.bind(function (results, status) {
          var mapOptions = _.defaults({
            center: results[0].geometry.location
          }, this.defaultMapOptions);
          // Avoid rendering default area map if we found a collection
          if (this.renderingCollection) { return; }
          new google.maps.Map(this.$el[0], mapOptions);
        }, this);
       var area = String(this.$el.data('area'));
        geocoder.geocode({ address: area }, displayMap);
      },

      renderMapCollection: function () {
        this.renderingCollection = true;
        var bounds = this._setBounds();
        var mapOptions = _.defaults({
          center: bounds.getCenter()
        }, this.defaultMapOptions);

        var map = new google.maps.Map(this.$el[0], mapOptions);

        // Don't set bounds when there's one search result returned or map will look very zoomed-in
        if (this.collection.length != 1) { map.fitBounds(bounds); }

        var setMarkers = function (model) {
          var marker = new exports.MapMarker({ model: model });
          marker.placeOn(map);
          this.markers.push(marker);
          google.maps.event.addListener(marker.marker, 'click', _.bind(function () {
            _.each(this.markers, function (marker) {
              marker.closeInfoWindow();
            });
            marker.showInfoWindow(map);
          }, this));
        };
        this.collection.each(setMarkers, this);
      },

      _setBounds: function () {
        var bounds = new google.maps.LatLngBounds()
        this.collection.each(function (model) {
          var point = new google.maps.LatLng(model.get('lat'), model.get('lng'));
          bounds.extend(point);
        });
        return bounds;
      }
    });
  });
