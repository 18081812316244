import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.AppointmentWidget', function(exports) {
  exports.PaymentMethodSelect = Backbone.View.extend({
    events: { },

    initialize: function() {
      this.render();
    },

    render: function() {
      var select = this.$el;
      this.collection.each(function(model) {
        select.append(
          $('<option>', {
            value: model.id,
            name: 'payment_method',
            text: model.get('name')
          })
        );
      });
    }
  });
});
