import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views', function (exports) {
  exports.CheckoutPage = Backbone.View.extend({
    el: '.internal_checkout.index',

    events: {
      'click .book-btn': 'submitCheckoutForm',
      // 'click .times, .time': 'changeTime', //TODO Add this back
      'change select': 'changeOption',
      'click .toggle-collapse': 'togglePromoView'
    },

    initialize: function () {
      var providerJSON = this.$('[data-provider-json]').data('providerJson');
      var serviceJSON = this.$('[data-service]').data('service');
      this.service = new Doctible.Models.Service(serviceJSON);
      this.servicePrice = this.service.get('display_price');
      this.providerModel = new Doctible.Models.Provider(providerJSON);
      this.$('.promo-code input').hide();

      new exports.Checkout.Promo({
        el: this.$('.promo-code'),
        model: new Backbone.Model({
          provider_id: this.providerModel.get('provider_id'),
          service_id: this.service.get('id')
        })
      });

      this._setRangedPrice();
      this._setFocus();
      this._setPhoneMask();
      this._setRatings();
      this.changeOption();
    },

    submitCheckoutForm: function(event){
      this._validateFields();
    },

    togglePromoView: function(event){
      var toggle_collapse_buttoin = this.$('.toggle-collapse');
      if (toggle_collapse_buttoin.hasClass("fa-chevron-down")){
        toggle_collapse_buttoin.removeClass("fa-chevron-down");
        this.$('.promo-code input').slideDown(100);
      }
    },

    changeOption: function() {
      if (this.$(".insurance-text").length > 0){
        var cashPayment = this.$('select').val() === 'cash';
        this.$('.visit-text, .price').toggle(cashPayment);
        this.$('.insurance-note').toggle(!cashPayment);
      }

      this.$('.promo-code').toggle(true);
    },

    _setPrice: function(price) {
      this.$('.price').text(price);
    },

    _setRangedPrice: function(){
      this._setPrice(this.service.cashPrice());
    },

    _validateFields: function(){
      $("#checkout-form").validate(
      {
        rules:
        {
          first_name: { required: true },
          last_name: { required: true },
          email: {
            required: true,
            email: true
          },
          phone_number:
          {
            required: true,
            rangelength: [14,14],
            phoneUS: true
          },
          terms: { required: true }
        },
        messages:
        {
          first_name: "Required field",
          last_name: "Required field",
          email: {
            required: "Required field",
            email: "Invalid email"
          },
          terms:{
            required: "Please accept our Terms of Services"
          },
          phone_number: {
            required: "Required field",
            rangelength: "Invalid phone number",
            remote: 'Invalid phone number'
          }
        },
        submitHandler: function(form) {
          $('#checkout-form')[0].submit();
        }
      });
    },

    _setFocus: function(){
      this.$('input[name=first_name]').focus();
    },

    _setPhoneMask: function(){
      $("input[name=phone_number]").mask("(000) 000-0000");
    },

    _setRatings: function () {
      var ratings = this.$('.provider-info .ratings');
      var rating = parseFloat(this.providerModel.get('average_rating'));
      ratings.raty({ score: rating, hints: [ '', '', '', '', '' ], readOnly: true });
    }
  })
});
