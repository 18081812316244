import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Models.Providers', function(exports) {
  exports.ReviewRequest = Backbone.Model.extend({
    urlRoot: '/api/providers/review_requests',

    asHash: function () {
      return _.extend(this.toJSON(), {
        numOfsitesClicked: this.numOfSitesClicked(),
        ratingDisplay: this.ratingDisplay(),
        reminderDisplay: this.reminderDisplay(),
        messageDisplay: this.messageDisplay(),
        contactInfoDisplay: this.contactInfoDisplay(),
        reminderButtonDisplay: this.reminderButtonDisplay()
      });
    },

    checkRecent: function(callback) {
      $.get(this.urlRoot + '/recently_sent', this.attributes)
        .done(callback)
    },

    reminderDisplay: function() {
      if (this.get('reminder_sent')){
        return "<i class='fa fa-check'></i>";
      };
    },

    ratingDisplay: function() {
      var rating = this.get('rating');
      if (rating ){
        var spanClass = (rating > 3) ? 'good-rating' : 'bad-rating';
        var experience = (rating > 3) ? 'Good' : 'Dissatisfied';
        return "<span class='" + spanClass + "'>"+ experience + "</span>"
      };
    },

    messageDisplay: function() {
     if (this.get('message')){
       return "<a class='btn-read-message'>Read</a>";
     };
    },

    contactInfoDisplay: function() {
     if (this.get('contact_method') == 'sms'){
       return this.get('phone_number');
     }else {
       return this.get('email');
     }
    },

    reminderButtonDisplay: function() {
     if (!this.get('clicked') && !this.get('reminder_sent')){
       return "<button class='btn btn-remind-reviewer' type='button'>Remind</button>";
     };
    },

    sitesClicked: function(){
      var sitesClicked = this.get('sites_clicked');
      var displaySites = [];
      _.each(sitesClicked, function(s){
        displaySites.push("<a href='" + s.url + "' target='_blank'>" + s.name + "</a>");
      });

      return displaySites;
    },

    numOfSitesClicked: function () {
      if (this.sitesClicked().length == 0){
        return null;
      }else{
        return this.sitesClicked().length
          + " visited <i class='fa fa-info-circle' data-toggle='popover'></i>";
      }
    }
  });
});
