import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Collections.Providers', function(exports) {
  exports.ReviewRequests = Backbone.Collection.extend({
    model: Doctible.Models.Providers.ReviewRequest,
    url: '/api/providers/review_requests',

    comparator: function(a, b) {
      return a.id < b.id ? 1 : -1;
    }
  });
});
