import '../support/model_template.js';
import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal', function(exports) {
  exports.EditRow = Doctible.Views.Support.ModelTemplate.extend({
    templateID: '#patients-row-template',

    events: {
      'click .text': 'switchToText',
      'click .phone': 'switchToPhone',
      'click .contact-by': 'switchActiveIcon',
      'click .delete-patient': 'deletePatient'
    },

    postRender: function() {
      var contactBy = this.model.get('contact_by');
      if (contactBy === 'email') {
        this.$('.phone-choices').hide();
        this.$('.email').addClass('active');
      } else {
        this.$('.email').hide();
        this.$('.' + contactBy).addClass('active');
      }
      this.checkStatus();
      this.listenTo(this.model, 'destroy', this.remove);
      this.listenTo(this.model, 'change:contact_count', this.checkStatus);
      this.listenTo(this.model, 'error', this.showError);
    },

    checkStatus: function() {
      var contact_count = this.model.get('contact_count');
      var warning = this.$('.will-not-contact-warning');
      if (!contact_count || warning.length === 0) return;
      warning.toggleClass('hidden', contact_count === 0).popover({
        content: warning.data('content-to-display').replace(/0 times/, contact_count + ' times')
      });
    },

    showError: function() {
      this.$('.request-warning').removeClass('hidden').popover();
    },

    switchToText: function() {
      this.model.save({ contact_by: 'text' });
    },

    switchToPhone: function() {
      this.model.save({ contact_by: 'phone' })
    },

    switchActiveIcon: function(event) {
      this.$('.contact-by').removeClass('active');
      $(event.currentTarget).addClass('active');
    },

    savePosition: function() {
      var newPosition = this.$el.index();
      if (newPosition > 0) this.model.save({ position: newPosition });
    },

    deletePatient: function() {
      var modal = new exports.RemoveContactModal({ model: this.model });
      modal.show();
    }
  });
});
