import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Search', function (exports) {
  exports.ProviderSpecialtyOnly = exports.Provider.extend({
    template: function () {
      return _.template($('#provider-specialty-only-template').text());
    }
  })
})
