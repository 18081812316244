import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Models', function (exports) {
  exports.Location = Backbone.Model.extend({
    asHash: function () {
      return this.toJSON();
    }
  });
});
