import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.RequestedAppointments', function (exports) {
  exports.ConfirmedAppointments = Backbone.View.extend({
    el: '.confirmed_appointments',

    initialize: function () {
      this.collection.bind('add', this.render, this);
      this.collection.bind('remove', this.render, this);
      this.appointments = this.collection.map(this._appendModel, this);
    },

    render: function(){
      _.invoke(this.appointments, 'remove');
      this.appointments = this.collection.map(this._appendModel, this);
    },

    _appendModel: function (model) {
      var appointment = new Doctible.Views.ProviderPortal.RequestedAppointments.ConfirmedAppointmentRow({ model: model });
      this.$el.append(appointment.$el);
      return appointment;
    }
  });
});
