import { namespace } from 'src/namespace';
import './../../support/model_template.js';

export default function() {};
namespace('Doctible.Views.ProviderPortal.RequestedAppointments', function (exports) {
  exports.BookedAppointmentRow = Doctible.Views.Support.ModelTemplate.extend({
    events: {
      'click .confirm-patient': 'launchDialog',
      'click .mark-no-show': 'markAppointmentNoShow',
      'click .cancel-appt': 'markAppointmentCanceled'
    },

    templateID: "#booked_appointment_template",

    launchDialog: function(){
      new Doctible.Views.ProviderPortal.RequestedAppointments.ConfirmAppointmentModal({ model: this.model });
    },

    markAppointmentNoShow: function() {
      $.ajax({
        url: "/api/requested_appointments/mark_as_no_show",
        type: "PUT",
        data: { "appointment_request_id": this.model.get("id") },
        success: _.bind(this._showNotification, this, 'Appointment marked as No Show.')
      });
    },

    markAppointmentCanceled: function() {
      $.ajax({
        url: "/api/requested_appointments/mark_as_canceled",
        type: "PUT",
        data: { "appointment_request_id": this.model.get("id") },
        success: _.bind(this._showNotification, this, 'Appointment marked as Canceled.')
      });
    },

    _showNotification: function(notice) {
      $('.notice-text').text(notice);
      $('p.notice').fadeIn(1000);
      this.remove();
    },

    postRender: function() {
      var preferences = this.model.get('preferences');
      if (preferences.length === 0) return;
      var preference = preferences[0];
      if (!preference.date || !preference.time_frame) return;
      var time_frames = this.model.timeFrameString();
      this.$('.appointment-time')
        .html(preference.date + ' Patient Requested:' + time_frames);
    }
  });
});
