import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Models', function (exports) {
  exports.RequestedAppointment = Backbone.Model.extend({
    urlRoot: '/api/requested_appointments',

    timeFrameString: function() {
      return _.map(this.get('preferences'), function(pref) {
        return '<br>' + pref.time_frame;
      }).join('');
    },

    asHash: function () {
      this.set({display_price: this._displayPrice()});
      return this.toJSON();
    },

    _displayPrice: function(){
      var ranged_price = this.get('ranged_price');

      if (ranged_price){
        return "$" + this.get('lower_price') + " ~ $" + this.get('upper_price');
      }
      else{
        return "$" + this.get('total_price');
      }
    }
  });
});
