import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.OfficeHours', function(exports) {
  exports.Hour = Backbone.View.extend({
    tagName: 'p',
    className: 'hour-view',

    events: {
      'click i': 'deleteModel'
    },

    days: {
      1: 'monday',
      2: 'tuesday',
      3: 'wednesday',
      4: 'thursday',
      5: 'friday',
      6: 'saturday',
      0: 'sunday'
    },

    initialize: function() {
      this.render();
      this.listenTo(this.model, 'destroy', this.remove)
    },

    render: function() {
      this.$el.append($('<span>', { class: 'hour-text', text: this.model.hourText() }));
      this.$el.append($('<i>', { class: 'fa fa-times' }));
    },

    dayClass: function() {
      return '.' + this.days[this.model.get('day')];
    },

    deleteModel: function() {
      this.model.destroy();
    }
  })
});
