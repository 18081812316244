import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Search', function(exports) {
  exports.AvailableTimesDay = Backbone.View.extend({
    className: 'col-sm-4',

    events: {
      'click .request-day-container': 'requestTime',
      'click .more-events': 'scrollEvents'
    },

    visibleEvents: 3,

    initialize: function() {
      this.render();
    },

    render: function() {
      this.$el.html(
        '<div class="date-container row">' +
          '<span class="date-name"></span>' +
          '<span class="date"></span>' +
        '</div>' +
        '<div class="events row"></div>' +
        '<div class="more-events">More</div>'
      );
      this.displayEvents();
      this.delegateEvents();
    },

    displayEvents: function() {
      if (this.collection.length > 0) {
        if (this.collection.findWhere({'closed': true})) {
          this.$('.events').remove();
          this.$el.append('<div class="row closed-container"></div>');
        }else{
          this.collection.each(_.bind(function(model) {
            model.set({'insurance_param': this.model.get('insurance_param')});
            var availableEvent = new exports.AvailableTimesEvent({ model: model });
            availableEvent.provider = this.model;
            this.$('.events').append(availableEvent.$el);
          }, this));
          if (this.collection.length > this.visibleEvents + 1) {
            this.$('.more-events').show()
          }
        }
      } else {
        this.$('.events').remove();
        this.$el.append(
          '<div class="request-day-container row">' +
            'Request <br/> Appointment' +
          '</div>'
        );
      }
    },

    scrollEvents: function() {
      var length = this.collection.length;
      var firstEvent = this.$('.event').first();
      // Get event height with margin of event that will not change
      var eventHeight = parseInt(this.$('.event').last().outerHeight(true));
      var totalHeight = length * eventHeight;
      var currentMarginTop = parseInt(firstEvent.css('margin-top'))
      // Use negative margin to pull event up
      var scrollHeight = currentMarginTop - eventHeight * this.visibleEvents;
      // Reset scrollHeight if we've gone past the end
      if (scrollHeight + totalHeight <= 1) {
        scrollHeight = 0;
      }
      firstEvent.css('margin-top', scrollHeight + 'px');
    },

    requestTime: function() {
      this.model.set({
        selected_service_id: this.model.get('services')[0].id,
        showRequestAppt: true,
        selectedDate: this.model.get('selectedDate')
      });

      var modal = new Doctible.Views.AppointmentAvailabilityModal({ model: this.model});
      var insurance = this.model.get('insurance_param');
      if (insurance && insurance !== 'cash') {
        modal.setInsuranceOption();
      }
    }
  })
});
