import RequestedAppointment from './requested_appointment.js';
import AvailableEvent from './available_event.js';
import AvailablePhoneNumber from './available_phone_number.js';
import BackgroundAttribute from './background_attribute.js';
import CalendarEvent from './calendar_event.js';
import CheckInPatient from './check_in_patient.js';
import FirstAvailableTime from './first_available_time.js';
import Location from './location.js';
import NewAppointment from './new_appointment.js';
import NewEvent from './new_event.js';
import OfficeHour from './office_hour.js';
import PatientMessage from './patient_message.js';
import PromoPrice from './promo_price.js';
import provider from './provider.js';
import ProviderAppointment from './provider_appointment.js';
import Service from './service.js';
import ProviderMedicalService from './provider_medical_service.js';
import Providers from './providers';
import Search from './search';
import Specialty from './specialty.js';

export default {
  RequestedAppointment,
  AvailableEvent,
  AvailablePhoneNumber,
  BackgroundAttribute,
  CalendarEvent,
  CheckInPatient,
  FirstAvailableTime,
  Location,
  NewAppointment,
  NewEvent,
  OfficeHour,
  PatientMessage,
  PromoPrice,
  provider,
  ProviderAppointment,
  Service,
  ProviderMedicalService,
  Providers,
  Search,
  Specialty,
};
