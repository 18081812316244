import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Search', function (exports) {
  exports.Provider = Backbone.View.extend({
    events: {
      'click .book-now-button': 'launchModal',
      'click .view-profile-button' : 'goToProfilePage',
      'click .see-more-services': 'goToProfileServices',
      'click .revivews_url': 'goToProfileReviews',
      'mouseenter': 'startHover',
      'mouseleave': 'endHover'
    },

    initialize: function () {
      this.render();
    },

    render: function () {
      this.setElement(this.renderedTemplate());
      this._setRatings();
      this._setPayingProvider();
      this._hideReviewCount();
      this.postRender();
    },

    renderedTemplate: function () {
      return this.template()(this.model.asHash());
    },

    postRender: function () {},

    goToProfilePage: function () {
      Doctible.Support.navigate(this.model.profileUrl());
    },

    goToProfileServices: function () {
      Doctible.Support.navigate(this.model.profileUrl() + "#all-services");
    },

    goToProfileReviews: function () {
      Doctible.Support.navigate(this.model.profileUrl() + "#patient-reviews");
    },

    _setRatings: function () {
      var ratings = this.$('.ratings').find('.doctor');
      var rating = parseFloat(this.model.get('average_rating'));
      if (rating > 0){
        ratings.raty({ score: rating, hints: [ '', '', '', '', '' ], readOnly: true });
      }
    },

    _hideReviewCount: function(){
      var reviews_count = this.model.get('reviews_count');
      if (reviews_count == 0)
        this.$('.reviews_count').hide();
    },

    _setPayingProvider: function(){
      var paying = this.model.get('paying');
      if(paying)
        this.$('.check-circle').css('display', 'inline-block').tooltip();
    },

    startHover: function () {
      this.model.trigger('mouseenter');
    },

    endHover: function () {
      this.model.trigger('mouseleave');
    },

    launchModal: function (event) {
      var target = $(event.target);
      this.model.set({
        selected_service_id: this.model.firstService().id,
        display_price: this.model.firstService().display_price
      });
      var modal = new Doctible.Views.AppointmentAvailabilityModal({model: this.model});
      var payingWithInsurance = this.model.insurance_param &&
                                this.model.insurance_param !== 'cash'
      if(target.hasClass("insurance-text") || payingWithInsurance) {
        modal.setInsuranceOption();
      }
    },

    showPrice: function() { }
  });
});
