import { namespace } from 'src/namespace';
import './event.js'

export default function() {};
namespace('Doctible.Views.ProviderPortal.Schedules', function(exports) {
  exports.ConfirmedEvent = exports.Event.extend({
    titleContent: function() {
      return this.title() + "<span class=\"popovericon process-patient\" title=\"Patient Confirm\"><i class=\"fa fa-map-marker\"></i></span>";
    }
  });
});
