import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Models', function(exports) {
  exports.NewEvent = Backbone.Model.extend({
    url: '/api/provider_scheduled_events/create_events',

    initialize: function() {
      this.on('sync', function() { Backbone.trigger('event-modified') });
    }
  })
})
