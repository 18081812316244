import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Shared', function (exports) {
  exports.BarChart = Backbone.View.extend({
    initialize: function () {
      this.render();
    },

    render: function () {
      var ctx = this.$el;
      var data = {
        // labels are the ones that show up under x-axis
        labels: this.model.get('xaxisLabels'),
        datasets: [
          {
            label: this.model.get('label'),
            fill: false,
            lineTension: 0.1,
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "rgba(75,192,192,1)",
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: "rgba(75,192,192,1)",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(75,192,192,1)",
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            backgroundColor: 'rgba(38, 156, 214, 0.8)',
            data: this.model.get('xaxisData'),
          }
        ]
      };
      var barChart = new Chart(ctx, {
        type: 'bar',
        data: data,
        options: {
          legend: {
            display: false,
          },
          scales : {
            xAxes : [ {
              gridLines : {
                display : false
              },
              ticks: {
                callback: function(dataLabel, index) {
                  return index % 5 === 0 ? dataLabel : '';
                }
              }
            }],
            yAxes: [ {
              ticks: {
                beginAtZero: true,   // minimum value will be 0.
                stepSize: this.model.get('stepSize'),
                suggestedMax: this.model.get('suggestedMax')
              }
            }]
          },
        }
      });
    }
  });
});
