import { namespace } from 'src/namespace';
import '../../support/model_template.js'

export default function() {};
namespace('Doctible.Views.ProviderPortal.Background', function (exports) {
  exports.AttributeItem = Doctible.Views.Support.ModelTemplate.extend({
    templateID: "#attribute_list_template",

    events: {
      'click .delete-attribute': 'deleteAttribute'
    },

    deleteAttribute: function(){
      this.model.destroy();
      this.remove();
    }
  });
});
