import './shared/preferred_times_selector.js';
import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views', function (exports) {
  exports.PreferredTimesSelector = exports.Shared.PreferredTimesSelector.extend({
    requestCheckout: function () {
      this.trigger('time-requested', this.formData());
    },

    formData: function () {
      return {
        'provider_location_id': this.model.get('id'),
        'preferred_times': this.preferredTimes(),
        'date': this.selectedDate(),
        'treatment': this.model.get("selected_service_id")
      };
    },
  });
});
