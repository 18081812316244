import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.Background', function (exports) {
  exports.AttributeForm = Doctible.Views.Support.Form.extend({
    el: '.attribute-form',

    events: {
      'click .btn-form': 'submitCreate'
    },

    initialize: function () {},

    submitCreate: function (event) {
      if (this.formData().attribute_value != '' ){
        this.collection.create(this.formData());
        this.$('.attribute-input').val('');
      };
    },
  });

});
