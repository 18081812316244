import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Models', function(exports) {
  exports.CalendarEvent = Backbone.Model.extend({
    initialize: function() {
      this.on('sync', function() {
        Backbone.trigger('event-modified');
      });
    },

    url: function() {
      return '/api/provider_scheduled_events/' + this.id;
    },

    asHash: function() {
      return _.extend(this.toJSON(), {
        startDisplay: this.get('start').format('MMMM Do YYYY, h:mm a'),
        endDisplay: this.get('end').format('MMMM Do YYYY, h:mm a')
      });
    },

    deleteAll: function() {
      this.destroy({ url: this.url() + '?all_occurrence=true' });
    },

    deleteOne: function() {
      this.destroy({ url: this.url() + '?all_occurrence=false' });
    }
  })
});
