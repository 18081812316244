import CandidateReviewRequests from './candidate_review_requests.js';
import LocationContacts from './location_contacts.js';
import LocationReviewSites from './location_review_sites.js';
import ProviderLocationHours from './provider_location_hours.js';
import ReviewRequests from './review_requests.js';

export default {
  CandidateReviewRequests,
  LocationContacts,
  LocationReviewSites,
  ProviderLocationHours,
  ReviewRequests,
};
