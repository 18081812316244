(function ($) {
  "use strict";

  var createButton = function (el, options) {
    var buttonColor = el.data('button-color') || options['buttonColor'] || '#3399f3';
    var textColor = el.data('text-color') || options['textColor'] || '#FFFFFF';
    var buttonText = el.data('button-text') || options['buttonText']
        || 'Book an Appointment';
    var type = el.data('type') || options['type'] || 'providers';
    var urlID = el.data('id') || options['id'];
    var appointmentButton = $('<a>', {
      style: 'display:inline-block; font-size: 15px; padding: 8px 12px; ' +
        'text-decoration: none; text-align: center; border-radius: 2px; ' +
        'background-color: ' + buttonColor + '; color: ' + textColor +
        '; min-width: 165px;',
      href: '/' + type + '/book_online/' + urlID,
      class: 'appointment-button',
      text: buttonText
    });
    el.html(appointmentButton);
  }

  var setupIFrame = function (el) {
    el.children(".appointment-button").click(function (e) {
      var iframe = $('<iframe>', {
        id: 'appointment-modal-dialog',
        style: 'position: fixed; left: 0px; top: 0px; z-index: 2147483647;' +
          'width: 100%; height: 100%;',
      });
      var appointmentModal = $('<div>', {class: 'appointment-modal'})
      appointmentModal.append(iframe);
        $('body').append(appointmentModal);
      $("#appointment-modal-dialog").attr('src', $(this).attr("href"));
      return false;
    });

    $(document).keyup(function(e) {
      if (e.keyCode == 27) { $('.appointment-modal').remove(); };
    });

    // Use attachEvent to handle closeWindow event bubbling up from iframe in IE
    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, function(e) {
      var key = e.message ? "message" : "data";
      var data = e[key];
      if (data === "closeWindow"){
        $('.appointment-modal').remove();
          return false;
      }
    }, false);
  };

  $.fn.doctiblePopup = function(options) {
    createButton(this, options || {});
    setupIFrame(this);
  }
})(jQuery);
