import { namespace } from 'src/namespace';
import 'jquery-smooth-scroll';

export default function() {};
namespace('Doctible.Views.Support', function (exports) {
  exports.SmoothScrollLink = Backbone.View.extend({
    el: '[href^=#]',

    initialize: function () {
      this.$el.smoothScroll({
        offset: this.$el.data('offset') || -40
      });
    }
  })
});
