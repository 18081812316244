import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.RequestedAppointments', function (exports) {
  exports.CheckInAppointmentModal = exports.AppointmentModal.extend({
    events: {
      'shown.bs.modal': 'render',
      'click .check-in': 'checkInPatient',
      'click .cancel': 'hide',
      'hidden.bs.modal': 'remove'
    },

    render: function () {
      this.$el.modal('show');
      this.$(".check-in").show();
    },

    checkInPatient: function() {
      this.checkin = new Doctible.Models.CheckInPatient();
      this.checkin.on('sync', this.afterCheckIn, this);
      this.checkin.fetch({
        data: {
          "appointment_request_id": this.model.get("id")
        }
      });
    },

    afterCheckIn: function(){
      $('p.notice').hide();
      if (this.checkin.get("ok") == true){
        Backbone.trigger('patient-checked-in', this.model);
        $('.notice-text').text("Patient " + this.model.get('name') + " checked in.");
        $('p.notice').fadeIn(1000);
      }else{
        Backbone.trigger('patient-check-in-failed', this.model);
      }
      this.hide();
    },
  });
});
