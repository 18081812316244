import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Models.Providers', function(exports) {
  exports.CandidateReviewRequest = Backbone.Model.extend({
    asHash: function () {
      return _.extend(this.toJSON(), {
        contact: this.contact()
      });
    },

    contact: function() {
      return this.get('email');
    }
  });
});
