import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views', function (exports) {
  exports.FaqPage = Backbone.View.extend({
    el: '.faq-container',

    events: {
      'click .question': 'toggleQuestion'
    },

    initialize: function () {
      this.$('.answer').hide();
    },

    toggleQuestion: function(event){
      var target = $(event.target);
      target.children('.answer').toggle('easing');
      target.children('i').toggleClass("fa-chevron-up fa-chevron-down");
    }
  })
});
