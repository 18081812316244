import { namespace } from 'src/namespace';
import moment from 'moment';

export default function() {};
namespace('Doctible.Views.Provider', function (exports) {
  exports.RequestedAppointments = Backbone.View.extend({
    initialize: function () {
      this.firstAvailableTime = new Doctible.Models.FirstAvailableTime();
      this.firstAvailableTime.on('sync', this.render, this);
      // TODO: Hold first available time on page to avoid extra request
      this.firstAvailableTime.fetch({
        data: {
          provider_location_id: this.model.get('id')
        }
      });
    },

    render: function () {
      var day = this.firstAvailableTime.get('day')
      if (day) {
        var formatted_date = moment(day)
          .format('MMMM D, YYYY, h:mm a')
        this.$('h3 span').text('Next Appointment: ' + formatted_date)
        $('.book-appointment-btn').css('display', 'block');
      } else {
        this.remove();
        // TODO: Bad form handling this here but it's relevant
        $('.book-appointment-btn').parent('div').remove();
      }
    },

    selectService: function (eventData) {
      this._modal = this._modal ||
        new exports.ServiceSelectModal({ model: this.model });
      this._modal.show(eventData)
    },

    showMobileCalendar: function() {
      var firstAvailableTime = this.firstAvailableTime.get('day');
      var calendar = new exports.Calendar({
        el: '.mobile-appointment-calendar-view',
        model: this.model
      });
      calendar.show(firstAvailableTime);
      calendar.on('datePicked', _.bind(this.selectService, this));
    }
  });
});
