import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Search.Filters', function(exports) {
  exports.Distance = exports.Base.extend({
    el: '.filter-section.distance',
    paramName: 'distance',

    events: {
      'click label.filter': 'toggle',
    },

    initialize: function() {
      this.$('select').select2({ minimumResultsForSearch: Infinity })
    },

    bindEvents: function() {
      this.$('select').off('change.filter');
      this.$('select').on('change.filter', _.bind(this.set, this));
    },

    setInputValue: function(value) {
      this.$('select').select2('val', value)
      this.bindEvents();
    }
  })
});
