import '../support/form.js';
import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.Provider', function (exports) {
  exports.ContactForm = Doctible.Views.Support.Form.extend({
    className: 'contact-form',

    validation: {
      rules:
      {
        name: 'required',
        email: {
          required: true,
          email: true
        },
        message: 'required'
      },
      messages:
      {
        name: 'Required',
        email: {
          required: 'Email is required',
          email: 'Invalid email'
        },
        message: {
          required: 'Please leave a message',
        }
      },
    },

    render: function() {
      this.$el.html(
        '<input class="form-control" type="text" placeholder="Your name" name="name" />' +
        '<input class="form-control" type="text" placeholder="Your email address" name="email" />' +
        '<textarea class="form-control" name="message" placeholder="Write your message here."></textarea>' +
        '<p class="disclosure">* Doctible does not share names or emails with any 3rd parties. Please do not post any information related to your health or condition on this form.</p>' +
        '<button class="btn standard-button" type="submit">' +
        'Send Message' +
        '</button>'
      );
    },

    submitForm: function() {
      // handle ajax send here
      this.trigger('submit');
      var message = new Doctible.Models.PatientMessage(
        _.extend({provider_location_id: this.model.get('id')}, this.formData())
      );
      message.save();
      return false;
    },

    setFocus: function() {
      this.$('input').first().focus();
    },

    removeFocus: function() {
      this.$(':focus').blur();
    }
  });
});
