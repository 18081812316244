import { namespace } from 'src/namespace';
import './row';

export default function() {};
namespace('Doctible.Views.ProviderPortal.Services', function(exports) {
  exports.EditRow = exports.Row.extend({
    events: {
      'click .fa-trash': 'showConfirmModal',
      'keyup .price input': 'checkInput',
      'blur input': 'setAttribute'
    },

    postRender: function() {
      this.checkInput();
    },

    checkInput: function() {
      var priceInput = this.$('.price input');
      priceInput.toggleClass('empty', priceInput.val().length === 0);
    },

    setAttribute: function(event) {
      var target = $(event.target)
      this.model.set(target.prop('name'), target.val());
    }
  })
})
