import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Collections', function (exports) {
  exports.Services = Backbone.Collection.extend({
    model: Doctible.Models.Service,

    comparator: function (model) {
      return model.get('position') || Infinity;
    }
  });
});
