import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Models', function(exports) {
  exports.ProviderAppointment = Backbone.Model.extend({
    url: function() {
      return '/api/requested_appointments/get/' + this.get('id');
    }
  })
})
