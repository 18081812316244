import AddServiceModal from './add_service_modal.js';
import AddServicesModal from './add_services_modal.js';
import Custom from './custom.js';
import EditPage from './edit_page.js';
import EditRow from './edit_row.js';
import EditServiceModal from './edit_service_modal.js';
import EditTable from './edit_table.js';
import Row from './row.js';
import Setup from './setup.js';
import Table from './table.js';
import { namespace } from 'src/namespace';

export default {
  AddServiceModal,
  AddServicesModal,
  Custom,
  EditPage,
  EditRow,
  EditServiceModal,
  EditTable,
  Row,
  Setup,
  Table,
};

namespace('Doctible.Views.ProviderPortal.Services', function(exports) {
  exports.Index = Backbone.View.extend({
    el: '.services.index',

    events: {
      'click .add-service': 'addService'
    },

    initialize: function() {
      this.collection = new Doctible.Collections.ProviderMedicalServices
      new Doctible.Views.ProviderPortal.Services.Table({ collection: this.collection });
    },

    addService: function() {
      (new exports.AddServiceModal({ collection: this.collection })).show();
    }
  });
});
