import './hour'
import { namespace } from 'src/namespace';

export default function() {};
namespace('Doctible.Views.ProviderPortal.OfficeHours', function(exports) {
  exports.Index = Backbone.View.extend({
    el: '.hours.index',

    events: {
      'click button.add-hour': 'addHour',
      'change [name=closed]': 'toggleForm'
    },

    collectionClass: Doctible.Collections.OfficeHours,

    initialize: function() {
      this.collection = new this.collectionClass();
      this.listenTo(this.collection, 'add', this.addHourView);
      this.collection.fetch({ success: _.bind(this.resetCollection, this) });
    },

    resetCollection: function() {
      // TODO: Change response structure so Backbone parses each hour element
      // into separate models
      // We will ideally be able to remove this entire method after
      // restructuring the response
      var arr = _.map(this.collection.models[0].attributes, function(attribute) {
        return attribute;
      });
      this.collection.reset();
      _.each(_.flatten(arr), _.bind(function(hour) {
        var model = new Doctible.Models.OfficeHour(hour);
        if (model.attributes.closed) {
          this.collection.add(model);
        }
      }, this));
      this.render();
    },

    render: function() {
      this.$('.hour-view').remove();
      this.collection.each(_.bind(this.addHourView, this));
    },

    addHour: function() {
      var data = {
        closed: this.$('[name=closed]').val(),
        day: parseInt(this.$('[name=day]').val())
      };
      if (data.closed === 'false') {
        var openTime = this.$('[name=openTimeHour]').val() + ':' +
                       this.$('[name=openTimeMinute]').val() + ' ' +
                       this.$('[name=openTimeMeridiem]').val();
        var closeTime = this.$('[name=closeTimeHour]').val() + ':' +
                        this.$('[name=closeTimeMinute]').val() + ' ' +
                        this.$('[name=closeTimeMeridiem]').val();
        data = _.extend(data, { open_time: openTime, close_time: closeTime });
      } else if (this.collection.where(data).length > 0) return;
      var openModels = this.collection.where({
        closed: data.closed === 'false' ? 'true' : 'false',
        day: data.day
      });
      _.each(openModels, function(model) { model.destroy(); });
      this.collection.create(data);
    },

    addHourView: function(model) {
      var hourView = new exports.Hour({ model: model });
      this.$(hourView.dayClass()).append(hourView.$el);
    },

    toggleForm: function() {
      this.$('.hours-selection').
        toggle(this.$('[name=closed]').val() === 'false');
    }
  });
});
