import { namespace } from 'src/namespace';
import missing from '../../../../../assets/images/medium/missing.png'

export default function() {};
namespace('Doctible.Models.Search', function (exports) {
  exports.Treatment = Backbone.Model.extend({
    asHash: function () {
      if (this.get('services')) {
        var hash = this.hashWithServices();
      } else {
        var hash = this.toJSON();
      }
      hash.distance = (hash.distance || 0).toFixed(1);
      hash.profile_url = this.profileUrl();
      return _.defaults(hash, this.defaults())
    },

    profileUrl: function () {
      return '/providers/' + this.get('url_stub') + this._queryString();
    },

    _queryString: function() {
      var queryParams = {};
      if (this.insurance_param) { queryParams.insurance = this.insurance_param }

      var service = this.firstService();
      if (service) { queryParams.sei = service.service_id }
      return '?' + $.param(queryParams);
    },

    hashWithServices: function () {
      return _.extend(this.toJSON(), {
        service_name: this.firstService().name,
        service_price: this.firstService().display_price,
        display_price: this.firstService().display_price
      });
    },

    hideRequestedAppointment: function () {
      return this.get('hide_req_appt') == true;
    },

    defaults: function () {
      return {
        provider_photo_url: missing
      }
    },

    firstService: function() {
      var services = this.get('services') || [];
      return services[0];
    },

    showsCashPrice: function() {
      // Display price will have been set to free service description if we
      // don't have a cash price to show
      return this.displayPrice() !== this.firstService().free_service_description;
    },

    displayPrice: function() {
      return this.firstService().display_price;
    }
  });
});
